import Icon from '@rsa-digital/evo-shared-components/components/Icon';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import { IconWrapper, StyledPanel, StyledText } from './styles';

type IconBannerProps = {
  text: string;
  icon: string;
} & ComponentProps;

const IconBanner: React.FC<IconBannerProps> = ({ text, icon, ...rest }) => {
  return (
    <StyledPanel borderShadows={{ displayOutset: true }} {...componentProps(rest)}>
      {icon && (
        <IconWrapper>
          <Icon size="large" name={icon} color={colors.notificationInfo} />
        </IconWrapper>
      )}
      <StyledText>{text}</StyledText>
    </StyledPanel>
  );
};

export default IconBanner;

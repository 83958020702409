import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { QuoteUpdaters } from 'apiHelpers/quote/useUpdateQuoteCover';
import { getCoverStartDate } from 'businessLogic/getCoverStartDate';
import { isPet8YearsOrOlderOnDate } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler, PropsWithChildren, useEffect, useState } from 'react';
import ExampleBox from 'components/ExampleBox';
import {
  VoluntaryExcessAmount,
  VoluntaryExcessPercentage,
} from 'helpers/businessConstants';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { PageTitle, trackTextButtonClick } from 'helpers/eventTracking';
import { getOptimizelyVariation } from 'helpers/optimizely';
import { formatStringToPercentage } from 'helpers/percentageHelper';
import {
  ExcessPlaceholderData,
  excessPlaceholders,
} from 'helpers/placeholders/excessPlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { petType_DOG } from 'helpers/referenceDataConstants';
import { useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useCurrentVetFeeLimit from 'helpers/useCurrentVetFeeLimit';
import { Age8ExcessAgreements } from 'state/formData/age8ExcessAgreements';
import { CsIcon } from 'types/contentStack';
import AdditionalExcess from './AdditionalExcess';
import Age8Excess from './Age8Excess';
import FixedExcess from './FixedExcess';
import {
  BorderWrapper,
  ExcessSectionWrapper,
  LargeRichTextWithModal,
  SectionWrapper,
  StyledLinkWithIconWithNoMargin,
  StyledPanel,
  StyledPanelDescription,
  StyledPanelHeading,
  TwoExcessSectionsWrapper,
  WrapperGrid,
} from './styles';

type ExcessSectionProps = {
  quoteUpdaters: QuoteUpdaters;
  pageTitle: PageTitle;
  age8ExcessAgreements: Age8ExcessAgreements;
  updateAge8ExcessAgreements: (
    update: Age8ExcessAgreements,
    group: 'petOver8' | 'petUnder8'
  ) => void;
  getError: FieldFunction<Age8ExcessAgreements, string | undefined>;
};

type CsChooseExcessSection = {
  csPetQuoteSummaryChooseExcess: {
    additional_vet_excess: {
      average_claim_value: number;
      how_your_excess_works: string;
      excess_examples: {
        excess_example_average_claim: string;
        excess_example_average_claim_payment: string;
        excess_example_large_claim: string;
        excess_example_large_claim_payment: string;
      };
    };
    fixed_vet_excess: {
      excess_description: string;
      additional_description_dogs_only: string;
    };
    email_quote_prominence: {
      heading: string;
      description: string;
      email_quote_button: {
        display_text: string;
        icon: [CsIcon];
      };
    };
  };
  csPetGlobalConfig: {
    loading_spinner: {
      email_spinner_text: string;
      excess_spinner_text: string;
      continue_spinner_text: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcess {
      additional_vet_excess {
        average_claim_value
        how_your_excess_works
        excess_examples {
          excess_example_average_claim
          excess_example_average_claim_payment
          excess_example_large_claim
          excess_example_large_claim_payment
        }
      }
      fixed_vet_excess {
        excess_description
        additional_description_dogs_only
      }
      email_quote_prominence {
        heading
        description
        email_quote_button {
          display_text
          icon {
            icon_code
          }
        }
      }
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
        excess_spinner_text
        continue_spinner_text
      }
    }
  }
`;

const ButtonLink: React.FC<PropsWithChildren<{
  icon: string;
  onClick: MouseEventHandler;
}>> = ({ icon, onClick, children }) => (
  <StyledLinkWithIconWithNoMargin onClick={onClick} iconCode={icon}>
    {children}
  </StyledLinkWithIconWithNoMargin>
);

const ExcessSection: React.FC<ExcessSectionProps> = ({
  quoteUpdaters,
  pageTitle,
  age8ExcessAgreements,
  updateAge8ExcessAgreements,
  getError,
}) => {
  const {
    csPetQuoteSummaryChooseExcess: {
      additional_vet_excess: {
        average_claim_value,
        how_your_excess_works,
        excess_examples: {
          excess_example_average_claim,
          excess_example_average_claim_payment,
          excess_example_large_claim,
          excess_example_large_claim_payment,
        },
      },
      fixed_vet_excess: { excess_description, additional_description_dogs_only },
      email_quote_prominence: { heading, description, email_quote_button },
    },
    csPetGlobalConfig: {
      loading_spinner: { email_spinner_text, continue_spinner_text },
    },
  } = useStaticQuery<CsChooseExcessSection>(query);

  const quote = useCurrentQuote();

  // We are tracking this internal state even if it's on the redux stored quote so that the state can update instantly for the screenreader users
  const [voluntaryExcessAmounts, setVoluntaryExcessAmounts] = useState<
    VoluntaryExcessAmount[] | undefined
  >();

  // We are tracking this internal state even if it's on the redux stored quote so that the state can update instantly for the screenreader users
  const [excessPercentageAmounts, setExcessPercentageAmounts] = useState<
    VoluntaryExcessPercentage[] | undefined
  >();

  useEffect(() => {
    setVoluntaryExcessAmounts(
      quote.petInfos?.map((petInfo) => petInfo.voluntaryExcessAmount)
    );

    setExcessPercentageAmounts(
      quote.petInfos?.map((petInfo) => petInfo.voluntaryExcessPercentage)
    );
  }, [quote]);

  const pets = quote.petInfos;
  const quoteHasDog = pets?.some((pet) => pet.petType === petType_DOG);

  const vetFeeLimit = useCurrentVetFeeLimit(quote.quoteOptions) || '';

  const [groupUnder8, setGroupUnder8] = useState<number[]>();
  const [groupOver8, setGroupOver8] = useState<number[]>();
  const hasPetUnder8 = !!groupUnder8 && groupUnder8.length > 0;
  const hasPetOver8 = !!groupOver8 && groupOver8.length > 0;
  useEffect(() => {
    const newGroupUnder8: number[] = [];
    const newGroupOver8: number[] = [];
    quote.petInfos?.forEach((pet, index) =>
      !!quote.petInfos &&
      isPet8YearsOrOlderOnDate(
        pet.dob,
        getCoverStartDate(quote.policyInfo?.coverStartDate)
      )
        ? newGroupOver8.push(index)
        : newGroupUnder8.push(index)
    );
    setGroupUnder8(newGroupUnder8);
    setGroupOver8(newGroupOver8);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quote]);

  const {
    saveAndEmailQuote,
    isSaveInProgress,
    savedQuoteConfirmationModalForQS,
  } = useSaveAndEmailQuote(PageTitle.QuoteSummary);

  const replaceIndexExcessPlaceholders = (
    index: number
  ): ((csRichTextString: string) => string) => {
    const excessPlaceholderData: ExcessPlaceholderData = {
      fixedExcess: quote.petInfos
        ? parseInt(quote.petInfos[index].voluntaryExcessAmount, 10)
        : 0,
      percentageExcess: excessPercentageAmounts
        ? formatStringToPercentage(excessPercentageAmounts[index])
        : 0,
      averageClaimValue: average_claim_value,
      vetFeeLimit,
    };
    return replacePlaceholdersRichText(excessPlaceholders, excessPlaceholderData);
  };

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;
  const emailIcon = unwrapSingleton(email_quote_button.icon);
  const loadingMsgText = (): string => {
    if (isSaveInProgress) return email_spinner_text;
    return continue_spinner_text;
  };

  const optimizelyEmailQuoteProminenceExperimentVariation = getOptimizelyVariation(
    process.env.GATSBY_OPTIMIZELY_EMAIL_QUOTE_PROMINENCE
  );

  const isOptimizelyEmailQuoteProminenceExperimentVariation =
    optimizelyEmailQuoteProminenceExperimentVariation === 'Variation #1';

  return (
    <WrapperGrid alignLeft>
      {isSaveInProgress && (
        <LoadingOverlayV2 loadingMessage={loadingMsgText()} timeDuration={10} />
      )}
      <SectionWrapper desktop={10} tabletLandscape={10} tabletPortrait={8}>
        {hasPetUnder8 &&
          groupUnder8 &&
          groupUnder8.map((index) => {
            return (
              <ExcessSectionWrapper
                data-cy={`petExcessSection[${index}]`}
                // eslint-disable-next-line react/no-array-index-key
                key={`petExcessSection[${index}]`}
                id={`petExcessSection[${index}]`}
                removeBottomMargin={hasPetUnder8 && hasPetOver8}>
                <TwoExcessSectionsWrapper>
                  <BorderWrapper>
                    <FixedExcess
                      quoteUpdaters={quoteUpdaters}
                      petIndex={index}
                      voluntaryExcessAmounts={voluntaryExcessAmounts}
                      setVoluntaryExcessAmounts={setVoluntaryExcessAmounts}
                    />
                  </BorderWrapper>
                  <BorderWrapper isSecondSection>
                    <AdditionalExcess
                      quoteUpdaters={quoteUpdaters}
                      petIndex={index}
                      excessPercentageAmounts={excessPercentageAmounts}
                      setExcessPercentageAmounts={setExcessPercentageAmounts}
                    />
                  </BorderWrapper>
                </TwoExcessSectionsWrapper>
                <ExampleBox
                  id={`fixedAndAdditionalExcessExampleBox[${index}]`}
                  pageTitle={pageTitle}
                  subheading={replaceIndexExcessPlaceholders(index)(
                    how_your_excess_works
                  )}
                  averageClaimText={replaceIndexExcessPlaceholders(index)(
                    excess_example_average_claim
                  )}
                  averageClaimPayment={replaceIndexExcessPlaceholders(index)(
                    excess_example_average_claim_payment
                  )}
                  largeClaimText={replaceIndexExcessPlaceholders(index)(
                    excess_example_large_claim
                  )}
                  largeClaimPayment={replaceIndexExcessPlaceholders(index)(
                    excess_example_large_claim_payment
                  )}
                />
              </ExcessSectionWrapper>
            );
          })}
        {!hasPetOver8 && (
          <ExcessSectionWrapper data-cy="additionalDescription">
            {quoteHasDog && (
              <LargeRichTextWithModal
                html={additional_description_dogs_only}
                pageTitle={pageTitle}
              />
            )}
            <LargeRichTextWithModal html={excess_description} pageTitle={pageTitle} />
          </ExcessSectionWrapper>
        )}
        {hasPetUnder8 && age8ExcessAgreements && (
          <ExcessSectionWrapper data-cy="petAge8ExcessSectionUnder8">
            <Age8Excess
              pageTitle={pageTitle}
              petIndexes={groupUnder8}
              age8ExcessAgreement={age8ExcessAgreements.petUnder8}
              updateAge8ExcessAgreements={(newValue) =>
                updateAge8ExcessAgreements(
                  { ...age8ExcessAgreements, petUnder8: newValue },
                  'petUnder8'
                )
              }
              error={getError('petUnder8')}
              groupIs8YearsOrOlder={false}
            />
          </ExcessSectionWrapper>
        )}
      </SectionWrapper>
      <SectionWrapper desktop={10} tabletLandscape={10} tabletPortrait={8}>
        {hasPetOver8 &&
          groupOver8 &&
          groupOver8.map((index) => {
            return (
              <ExcessSectionWrapper
                data-cy={`petExcessSection[${index}]`}
                // eslint-disable-next-line react/no-array-index-key
                key={`petExcessSection[${index}]`}
                id={`petExcessSection[${index}]`}
                removeBottomMargin>
                <BorderWrapper>
                  <FixedExcess
                    quoteUpdaters={quoteUpdaters}
                    petIndex={index}
                    voluntaryExcessAmounts={voluntaryExcessAmounts}
                    setVoluntaryExcessAmounts={setVoluntaryExcessAmounts}
                  />
                </BorderWrapper>
              </ExcessSectionWrapper>
            );
          })}
        {hasPetOver8 && age8ExcessAgreements && (
          <ExcessSectionWrapper data-cy="petAge8ExcessSectionOver8">
            <Age8Excess
              pageTitle={pageTitle}
              petIndexes={groupOver8}
              age8ExcessAgreement={age8ExcessAgreements.petOver8}
              updateAge8ExcessAgreements={(newValue) =>
                updateAge8ExcessAgreements(
                  { ...age8ExcessAgreements, petOver8: newValue },
                  'petOver8'
                )
              }
              error={getError('petOver8')}
              groupIs8YearsOrOlder
            />
          </ExcessSectionWrapper>
        )}
        {hasPetOver8 && (
          <ExcessSectionWrapper data-cy="additionalDescription">
            {quoteHasDog && (
              <LargeRichTextWithModal
                html={additional_description_dogs_only}
                pageTitle={pageTitle}
              />
            )}
            <LargeRichTextWithModal html={excess_description} pageTitle={pageTitle} />
          </ExcessSectionWrapper>
        )}
      </SectionWrapper>
      {savedQuoteConfirmationModalForQS}
      {isOptimizelyEmailQuoteProminenceExperimentVariation && (
        <SectionWrapper desktop={10} tabletLandscape={10} tabletPortrait={8}>
          <StyledPanel>
            <StyledPanelHeading>
              {plainTextPlaceholderReplacer(heading)}
            </StyledPanelHeading>
            <StyledPanelDescription>{description}</StyledPanelDescription>
            {emailIcon && (
              <ButtonLink
                onClick={() => {
                  saveAndEmailQuote();
                  trackTextButtonClick(pageTitle, email_quote_button.display_text);
                }}
                icon={emailIcon.icon_code}>
                {email_quote_button.display_text}
              </ButtonLink>
            )}
          </StyledPanel>
        </SectionWrapper>
      )}
    </WrapperGrid>
  );
};

export default ExcessSection;

import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled from 'styled-components';

export const StyledSpanLarge = styled.span`
  ${fonts.paragraphLarge};
  margin: ${spacing(1)} 0;
  display: block;
  & {
    font-weight: bold;
  }
`;

export const LightRichText = styled(RichText)`
  p {
    font-weight: ${lightFontWeight};
  }
`;

export const ParagraphLightWithMarginTop = styled.p`
  ${fonts.paragraphSmall};
  & {
    font-weight: ${lightFontWeight};
  }

  margin: ${spacing(2)} 0 0;
`;

export const VetFeeLimitSectionContainer = styled.div`
  margin: ${spacing(3)} 0 ${spacing(5)};

  ${mediaQuery.tabletPortrait`
    margin: ${spacing(6)} 0 ${spacing(4)};
  `}
`;

import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled from 'styled-components';

export const FeatureContainer = styled.div`
  display: flex;
  margin: ${spacing(2)} 0;
  align-items: center;
  page-break-inside: avoid;
`;

export const StyledButtonLink = styled(ButtonLink)`
  ${fonts.paragraph}
  & {
    color: ${colors.core01};
  }
  text-decoration: underline;
  text-align: left;
  display: inline;
`;

export const ParagraphSpanLight = styled.span`
  ${fonts.paragraph};
  & {
    font-weight: ${lightFontWeight};
  }
  margin: 0;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${spacing(2)};
`;

export const InlineDiv = styled.div`
  display: inline;
  padding: 0;
  margin: 0;
`;

import React from 'react';
import ProductFeaturesTable, {
  PetType,
  ProductFeature,
} from 'components/QuoteSummary/ProductFeaturesTable';
import { PageTitle } from 'helpers/eventTracking';
import { Product } from 'helpers/productHelpers';
import { ChecklistSectionHeading } from './styles';

type ProductFeatureSectionProps = {
  sectionHeading: string;
  features: ProductFeature[];
  product: Product;
  insuredPetTypes: PetType;
  pageTitle: PageTitle;
};

const ProductFeatureSection: React.FC<ProductFeatureSectionProps> = ({
  sectionHeading,
  features,
  product,
  insuredPetTypes,
  pageTitle,
}) => (
  <>
    <ChecklistSectionHeading>{sectionHeading}</ChecklistSectionHeading>
    <ProductFeaturesTable
      features={features}
      product={product}
      insuredPetTypes={insuredPetTypes}
      pageTitle={pageTitle}
    />
  </>
);

export default ProductFeatureSection;

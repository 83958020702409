import { scrollToElement } from '@rsa-digital/evo-shared-components/helpers/scroll';
import { getCoverStartDate } from 'businessLogic/getCoverStartDate';
import { isPet8YearsOrOlderOnDate } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { getProductFromQuoteOptions, Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useCurrentVetFeeLimit from 'helpers/useCurrentVetFeeLimit';
import {
  EmphasisedText,
  EmphasisedTextWithoutPadding,
  Mobile,
  MobilePriceBannerTextWrapper,
  PriceBannerTextWrapper,
  StyledDiv,
  StyledLinkText,
  StyledText,
  TabletLandscape,
  TabletPortrait,
  VerticalDivider,
} from './styles';

export type PriceBannerTextProps = {
  csPetQuoteSummary: {
    price_banner: {
      monthly_label: string;
      annual_label: string;
      vet_fee_limit_text: string;
      fixed_excess_text: string;
      chosen_excess_text: string;
      age_excess: string;
      premier_cover: string;
      extra_cover: string;
      standard_cover: string;
      excess_link_text: string;
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummary {
      price_banner {
        monthly_label
        annual_label
        vet_fee_limit_text
        fixed_excess_text
        chosen_excess_text
        age_excess
        premier_cover
        extra_cover
        standard_cover
        excess_link_text
      }
    }
  }
`;

export const PriceBannerText: React.FC = () => {
  const { price_banner } = useStaticQuery<PriceBannerTextProps>(query).csPetQuoteSummary;

  const quote = useCurrentQuote();

  const product = getProductFromQuoteOptions(quote.quoteOptions);

  const vetFeeLimit = useCurrentVetFeeLimit(quote.quoteOptions);

  const getCoverLevelText = (): string => {
    switch (product) {
      case Product.Standard:
        return price_banner.standard_cover;
      case Product.Extra:
        return price_banner.extra_cover;
      case Product.Premier:
        return price_banner.premier_cover;
      default:
        return '';
    }
  };

  const getVoluntaryExcess = (): string | undefined => {
    if (!quote.petInfos) {
      return undefined;
    }
    return formatInPoundsWithSign(parseInt(quote.petInfos[0].voluntaryExcessAmount, 10));
  };

  const getVoluntaryExcessPercentage = (): string | undefined => {
    if (!quote.petInfos) {
      return undefined;
    }
    const voluntaryPercentageText = `${parseInt(
      quote.petInfos[0].voluntaryExcessPercentage,
      10
    )}%`;
    return voluntaryPercentageText;
  };

  const getSecondaryExcessText = (): string => {
    return isPet8YearsOrOlderOnDate(
      quote.petInfos?.[0].dob,
      getCoverStartDate(quote.policyInfo?.coverStartDate)
    )
      ? price_banner.age_excess
      : price_banner.chosen_excess_text;
  };

  const isMultiPet = (): boolean => {
    return (quote.petInfos?.length || 0) > 1;
  };

  return (
    <>
      <TabletLandscape>
        <PriceBannerTextWrapper>
          <EmphasisedTextWithoutPadding>
            {getCoverLevelText()}
          </EmphasisedTextWithoutPadding>
          <VerticalDivider />
          <EmphasisedText>{vetFeeLimit}</EmphasisedText>
          <StyledText>{price_banner.vet_fee_limit_text}</StyledText>
          <VerticalDivider />
          {!isMultiPet() && (
            <>
              <EmphasisedText>{getVoluntaryExcess()}</EmphasisedText>
              <StyledText>{price_banner.fixed_excess_text}</StyledText>
              {!!getVoluntaryExcessPercentage() && (
                <>
                  <EmphasisedTextWithoutPadding>+</EmphasisedTextWithoutPadding>
                  <EmphasisedTextWithoutPadding>
                    {getVoluntaryExcessPercentage()}
                  </EmphasisedTextWithoutPadding>
                  <StyledText>{getSecondaryExcessText()}</StyledText>
                </>
              )}
            </>
          )}
          {isMultiPet() && (
            <StyledLinkText onClick={() => scrollToElement('excess-section', 10)}>
              {price_banner.excess_link_text}
            </StyledLinkText>
          )}
        </PriceBannerTextWrapper>
      </TabletLandscape>
      <TabletPortrait>
        <MobilePriceBannerTextWrapper>
          <EmphasisedText>{getCoverLevelText()}</EmphasisedText>
          <StyledDiv>
            <EmphasisedText>{vetFeeLimit}</EmphasisedText>
            <StyledText>{price_banner.vet_fee_limit_text}</StyledText>
          </StyledDiv>
          <StyledDiv>
            {!isMultiPet() && (
              <>
                <EmphasisedText>{getVoluntaryExcess()}</EmphasisedText>
                <StyledText>{price_banner.fixed_excess_text}</StyledText>
                {!!getVoluntaryExcessPercentage() && (
                  <>
                    <EmphasisedTextWithoutPadding>+</EmphasisedTextWithoutPadding>
                    <EmphasisedTextWithoutPadding>
                      {getVoluntaryExcessPercentage()}
                    </EmphasisedTextWithoutPadding>
                    <StyledText>{getSecondaryExcessText()}</StyledText>
                  </>
                )}
              </>
            )}
            {isMultiPet() && (
              <StyledLinkText onClick={() => scrollToElement('excess-section', 10)}>
                {price_banner.excess_link_text}
              </StyledLinkText>
            )}
          </StyledDiv>
        </MobilePriceBannerTextWrapper>
      </TabletPortrait>
      <Mobile>
        <MobilePriceBannerTextWrapper>
          <EmphasisedText>{getCoverLevelText()}</EmphasisedText>
          <StyledDiv>
            <EmphasisedText>{vetFeeLimit}</EmphasisedText>
            <StyledText>{price_banner.vet_fee_limit_text}</StyledText>
          </StyledDiv>
          <StyledDiv>
            {!isMultiPet() && (
              <>
                <EmphasisedText>{getVoluntaryExcess()}</EmphasisedText>
                <StyledText>{price_banner.fixed_excess_text}</StyledText>
              </>
            )}
            {isMultiPet() && (
              <StyledLinkText onClick={() => scrollToElement('excess-section', 10)}>
                {price_banner.excess_link_text}
              </StyledLinkText>
            )}
          </StyledDiv>
          <StyledDiv>
            {!!getVoluntaryExcessPercentage() && !isMultiPet() && (
              <>
                <EmphasisedTextWithoutPadding>
                  {getVoluntaryExcessPercentage()}
                </EmphasisedTextWithoutPadding>
                <StyledText>{getSecondaryExcessText()}</StyledText>
              </>
            )}
          </StyledDiv>
        </MobilePriceBannerTextWrapper>
      </Mobile>
    </>
  );
};

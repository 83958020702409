import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import ConfirmationBox from 'components/ConfirmationBox';
import QuestionField from 'components/QuestionField';
import RichTextWithModal from 'components/RichTextWithModal';

export const ConfirmationBoxWithMarginBottom = styled(ConfirmationBox)`
  margin-bottom: ${spacing(4)};
`;

export const StyledRichText = styled(RichTextWithModal)`
  p {
    ${fonts.paragraphLarge}
  }
`;

export const QuestionFieldWithoutMarginBottom = styled(QuestionField)`
  && {
    margin-bottom: 0;
  }
`;

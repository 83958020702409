import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { QuoteUpdaters } from 'apiHelpers/quote/useUpdateQuoteCover';
import { graphql, useStaticQuery } from 'gatsby';
import { trimEnd } from 'lodash';
import React, { SetStateAction } from 'react';
import { VoluntaryExcessAmount } from 'helpers/businessConstants';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackExcessSelection } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { replacePlaceholdersQuestion } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion } from 'types/contentStack';
import {
  ExplanatoryText,
  StyledSegmentedSelector,
  SubheadingSmall,
  Wrapper,
} from './styles';

type FixedExcessSectionProps = {
  quoteUpdaters: QuoteUpdaters;
  petIndex: number;
  voluntaryExcessAmounts: VoluntaryExcessAmount[] | undefined;
  setVoluntaryExcessAmounts: React.Dispatch<
    SetStateAction<VoluntaryExcessAmount[] | undefined>
  >;
};

type CsFixedExcessSection = {
  csPetQuoteSummaryChooseExcess: {
    fixed_vet_excess: {
      choose_fixed_excess_question: CsQuestion;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcess {
      fixed_vet_excess {
        choose_fixed_excess_question {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
          }
        }
      }
    }
  }
`;

const FixedExcess: React.FC<FixedExcessSectionProps> = ({
  quoteUpdaters,
  petIndex,
  voluntaryExcessAmounts,
  setVoluntaryExcessAmounts,
}) => {
  const csContent = useStaticQuery<CsFixedExcessSection>(query)
    .csPetQuoteSummaryChooseExcess.fixed_vet_excess;
  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);
  const excessQuestion = processQuestion(csContent.choose_fixed_excess_question);

  const quote = useCurrentQuote();

  const defaultErrorHandling = useDefaultErrorHandling();
  const pet = quote.petInfos?.[petIndex];

  const substituteName = replacePlaceholdersQuestion((csString: string) =>
    petNameReplacer(trimEnd(pet?.petName) || '', csString)
  );

  return (
    <Wrapper>
      <div>
        <SubheadingSmall>{substituteName(excessQuestion).questionText}</SubheadingSmall>
        <ExplanatoryText>
          {substituteName(excessQuestion).explanatoryText}
        </ExplanatoryText>
      </div>
      <Grid alignLeft id={`petExcessQuestion_[${petIndex}]`}>
        <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8}>
          <StyledSegmentedSelector
            disabled={!!quoteUpdaters?.isLoading}
            id={`petExcess[${petIndex}]`}
            shownOptions={3}
            value={voluntaryExcessAmounts?.[petIndex]}
            options={[
              { name: '£60', value: VoluntaryExcessAmount.EXCESS_60 },
              { name: '£120', value: VoluntaryExcessAmount.EXCESS_120 },
              {
                name: '£200',
                value: VoluntaryExcessAmount.EXCESS_200,
              },
            ]}
            onChange={async (e) => {
              if (!quoteUpdaters) {
                console.error('No quote loaded - could not update quote');
                return;
              }
              const voluntaryExcessAmountsUpdated = voluntaryExcessAmounts
                ? [...voluntaryExcessAmounts]
                : [];
              voluntaryExcessAmountsUpdated[petIndex] = e.target
                .value as VoluntaryExcessAmount;

              setVoluntaryExcessAmounts(voluntaryExcessAmountsUpdated);
              trackExcessSelection(petIndex, e.target.value as VoluntaryExcessAmount);
              try {
                await quoteUpdaters.updatePetExcess(
                  petIndex,
                  e.target.value as VoluntaryExcessAmount
                );
              } catch (err) {
                const error: Error = err as Error;
                defaultErrorHandling(error);
              }
            }}
          />
        </GridItem>
      </Grid>
    </Wrapper>
  );
};

export default FixedExcess;

import {
  unorderedListItemStyling,
  unorderedListStyling,
} from '@rsa-digital/evo-shared-components/components/RichText/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { LargeRichTextWithModal } from '../styles';

export const MarginlessLargeRichTextWithModal = styled(LargeRichTextWithModal)`
  margin: 0;
`;

export const BoldParagraphLargeWithMarginBottom = styled.p`
  ${fonts.paragraphLarge};
  & {
    font-weight: bold;
  }
  margin-bottom: ${spacing(2)};
`;

export const StyledUnorderedList = styled.ul`
  ${unorderedListStyling};
`;

export const StyledListItem = styled.li`
  ${unorderedListItemStyling};
  // This is necessary since this uses the unorderedListItemStyling from richText, but we can't use 'class="large"' here as we would on richText, so the bullet point would be misaligned
  &::before {
    top: ${spacing(1.5)};
  }
`;

import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { IconWrapper } from '@rsa-digital/evo-shared-components/components/Icon/styles';
import InfoCard from '@rsa-digital/evo-shared-components/components/InfoCard/InfoCard';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled from 'styled-components';
import PanelWithActionLink from 'components/PanelWithActionLink';
import {
  ActionLinkWithTopMargin,
  StyledSpanHeadingMedium,
} from 'components/PanelWithActionLink/styles';

export const StyledPricePanel = styled(PanelWithActionLink)`
  ${StyledSpanHeadingMedium} {
    ${fonts.headingLarge}
  }

  ${mediaQuery.tabletPortrait`
    align-items: flex-start;

    span, ${ActionLinkWithTopMargin} {
      text-align: left;
    }

    padding: ${spacing(3)} ${spacing(4)};
  `}
`;

export const StyledGridItemMobile = styled(GridItem)`
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    display: none;
  `};
`;

export const StyledGridItemDesktop = styled(GridItem)`
  display: none;
  ${mediaQuery.tabletPortrait`
      display: block;
      margin-top: ${spacing(3)}
  `}
`;

export const PayMonthlyPromoInfoCard = styled(InfoCard)`
  margin: 0 0 ${spacing(4)};
  padding: 0;
  border: none;

  h3 {
    margin-top: 0;
    margin-bottom: 0;
    line-height: ${spacing(3)};
    height: ${spacing(3)};
    font-size: 1rem;
  }

  ${IconWrapper} {
    height: ${spacing(3)};
    flex: 0 0 ${spacing(3)};
    margin-right: ${spacing(1.25)};
  }

  svg {
    margin: 0;
    width: ${spacing(3)};
    height: ${spacing(3)};
  }

  div {
    margin-top: 0;
  }

  p {
    margin: ${spacing(1)} 0 0;
    font-weight: ${lightFontWeight};
    font-size: 1rem;
  }
`;

export const StyledDividerDesktop = styled(FlexibleWidthDivider)`
  display: none;

  ${mediaQuery.tabletPortrait`
      display: block;
      margin: 0;
  `}
`;

import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { generateKeyForElement } from '@rsa-digital/evo-shared-components/helpers/elementKeyHelper';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import { petPlaceholders } from 'helpers/placeholders/petPlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { isCoinsuranceQuote } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import CoinsuranceExcessStatements from './CoinsuranceExcessStatements';
import { ExcessStatementWrapper, LargeRichTextWithModal, StyledGrid } from './styles';
import { useSummaryText } from './useSummaryText';

type CsSummaryOfNeedsTextSection = {
  csPetQuoteSummaryOfYourNeeds: {
    text_summary: {
      per_pet_excess_statement: string;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeeds {
      text_summary {
        per_pet_excess_statement
      }
    }
  }
`;

const SummaryOfYourNeedsTextSection: React.FC<{ pageTitle: PageTitle }> = ({
  pageTitle,
}) => {
  const { per_pet_excess_statement } = useStaticQuery<CsSummaryOfNeedsTextSection>(
    query
  ).csPetQuoteSummaryOfYourNeeds.text_summary;
  const quote = useCurrentQuote();

  const substituteQuotePlacholders = replacePlaceholdersRichText(
    quotePlaceholders,
    quote
  );

  const summaryText = useSummaryText();

  const substituteExcessStatementPetPlaceholders = (pet: QuoteResponsePetInfo): string =>
    replacePlaceholdersRichText(petPlaceholders, pet)(per_pet_excess_statement);

  const isCoinsurance = isCoinsuranceQuote;

  return (
    <>
      {summaryText && (
        <StyledGrid alignLeft>
          <GridItem desktop={8} tabletLandscape={8}>
            <LargeRichTextWithModal
              pageTitle={pageTitle}
              html={substituteQuotePlacholders(summaryText?.text_before_statement)}
            />
            <ExcessStatementWrapper>
              {isCoinsurance
                ? quote.petInfos && (
                    <CoinsuranceExcessStatements
                      pageTitle={pageTitle}
                      petInfos={quote.petInfos}
                      textAfterStatement={substituteQuotePlacholders(
                        summaryText?.text_after_statement
                      )}
                    />
                  )
                : quote.petInfos?.map((pet) => (
                    <LargeRichTextWithModal
                      pageTitle={pageTitle}
                      key={generateKeyForElement(pet)}
                      html={substituteExcessStatementPetPlaceholders(pet)}
                    />
                  ))}
            </ExcessStatementWrapper>
            {!isCoinsuranceQuote && (
              <LargeRichTextWithModal
                pageTitle={pageTitle}
                html={substituteQuotePlacholders(summaryText?.text_after_statement)}
              />
            )}
          </GridItem>
        </StyledGrid>
      )}
    </>
  );
};

export default SummaryOfYourNeedsTextSection;

import { GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import ConfirmationBox from 'components/ConfirmationBox';
import PromotionalBox from 'components/PromotionalBox';
import RichTextWithModal from 'components/RichTextWithModal';

export const LargeRichTextWithModalAndNoMargins = styled(RichTextWithModal)`
  p {
    ${fonts.paragraphLarge}
  }
`;

export const StyledParagraph = styled.p`
  ${fonts.paragraphLarge};
  margin-top: ${spacing(6)};
  margin-bottom: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
    margin-bottom: ${spacing(4)};
  `}
`;

export const LargeTextParagraphWithSmallMargins = styled.p`
  ${fonts.paragraphLarge};
  margin-top: ${spacing(1)};
  margin-bottom: ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
  `}

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(2)};
  `}
`;

export const StyledGridItem = styled(GridItem)`
  margin-left: 0;
  margin-bottom: ${spacing(4)};

  ${mediaQuery.tabletLandscape`
    margin-top: ${spacing(6)};
  `}
`;

export const PriceBannerSpace = styled.div`
  height: ${spacing(12)};

  ${mediaQuery.tabletPortrait`
    height: ${spacing(8)};
  `}
`;

export const ConfirmationBoxWithMargin = styled(ConfirmationBox)`
  margin: ${spacing(-1)} 0 ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-bottom: ${spacing(4)};
    margin-top: ${spacing(-2)};
  `}
`;

/* We are rendering a hidden copy of the clubcard component on the quote summary page for direct quotes.
FreshEgg will be running an AB Test which unhides this component.
When the AB test concludes, if successful this component should be removed, and all quotes (aggs and direct) should use the existing component. */
export const HiddenClubcardSection = styled.div`
  display: none;
`;

const media = mediaQuery.tabletPortrait`margin-bottom: ${spacing(4)};`;
export const StyledPromotionalBox = styled(PromotionalBox)`
  margin-bottom: ${spacing(2)};
  ${media}
`;

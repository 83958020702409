import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import React, { MouseEventHandler } from 'react';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import {
  LightRichText,
  ParagraphLightWithMarginTop,
  StyledSpanLarge,
  VetFeeLimitSectionContainer,
} from './styles';
import { ButtonLinkContainer, StyledButtonLink, StyledSpan } from '../styles';

type VetFeeLimitSectionProps = {
  vetFeeLimitText: string | undefined;
  vetFeeLimit: string;
  forEachText: string;
  optionalOrText?: string;
  coverDurationText: string;
  treatmentCostLink?: string;
  treatmentCostOnClick: MouseEventHandler;
  pageTitle: PageTitle;
  extraOrPremierQuote: boolean;
  percentageOfClaimsCoveredDisclaimer: string;
};

const VetFeeLimitSection: React.FC<VetFeeLimitSectionProps> = ({
  vetFeeLimitText,
  vetFeeLimit,
  forEachText,
  optionalOrText,
  coverDurationText,
  treatmentCostLink,
  treatmentCostOnClick,
  pageTitle,
  extraOrPremierQuote,
  percentageOfClaimsCoveredDisclaimer,
}) => (
  <VetFeeLimitSectionContainer>
    {!extraOrPremierQuote && (
      <>
        <StyledSpan>{vetFeeLimitText} </StyledSpan>
        <StyledSpanLarge>{vetFeeLimit}</StyledSpanLarge>
        <StyledSpan>{forEachText} </StyledSpan>
        {optionalOrText && <StyledSpanLarge>{optionalOrText}</StyledSpanLarge>}
      </>
    )}
    <LightRichText
      html={coverDurationText}
      onLinkClick={trackRichTextLinkClick(pageTitle)}
    />
    {extraOrPremierQuote && (
      <ParagraphLightWithMarginTop>
        {percentageOfClaimsCoveredDisclaimer}
      </ParagraphLightWithMarginTop>
    )}
    {treatmentCostLink && (
      <ButtonLinkContainer>
        <StyledButtonLink
          data-cy="treatmentCostModalButton"
          onClick={treatmentCostOnClick}
          color={colors.core01}>
          {treatmentCostLink}
        </StyledButtonLink>
      </ButtonLinkContainer>
    )}
  </VetFeeLimitSectionContainer>
);

export default VetFeeLimitSection;

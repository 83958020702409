import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import React, { useState } from 'react';
import CustomModal from 'components/CustomModal';
import { PageTitle, trackRichTextLinkClick } from 'helpers/eventTracking';
import { Product } from 'helpers/productHelpers';
import { CsApplicableProducts, CsModal } from 'types/contentStack';
import ProductFeatureComponent from './ProductFeature';
import { ProductFeaturesTableContainer } from './styles';

export enum PetType {
  AllCases = 'All cases',
  CatsAndDogs = 'Cats and Dogs',
  CatsOnly = 'Cats only',
  DogsOnly = 'Dogs only',
}

type ProductFeaturesTableProps = {
  features: ProductFeature[];
  product: Product;
  insuredPetTypes: PetType;
  pageTitle: PageTitle;
};

export type ProductFeature = {
  display_on_products_selector: CsApplicableProducts;
  included_with_products_selector: CsApplicableProducts;
  applicable_pets: string[];
  modal: [] | [CsModal];
  feature_text: string;
};

const ProductFeaturesTable: React.FC<ProductFeaturesTableProps> = ({
  features,
  product,
  insuredPetTypes,
  pageTitle,
}) => {
  const [modalId, setModalId] = useState<string | null>(null);
  const getApplicableFeatures = (): ProductFeature[] => {
    return features.filter(
      (feature) =>
        feature.display_on_products_selector.product_selector.includes(product) &&
        (feature.applicable_pets.includes('All cases') ||
          feature.applicable_pets.includes(insuredPetTypes))
    );
  };

  return (
    <ProductFeaturesTableContainer>
      <CustomModal
        modalId={modalId}
        onClose={() => setModalId(null)}
        trackRichTextLinkClick={trackRichTextLinkClick(pageTitle)}
        pageTitle={pageTitle}
      />
      {getApplicableFeatures().map((feature, index) => {
        return (
          <li key={feature.feature_text}>
            {index > 0 && (
              <FlexibleWidthDivider
                desktop={10}
                tabletLandscape={10}
                tabletPortrait={8}
                aria-hidden
              />
            )}
            <ProductFeatureComponent
              includedWithProducts={
                feature.included_with_products_selector.product_selector
              }
              modal={feature.modal}
              product={product}
              featureText={feature.feature_text}
              setModalId={setModalId}
            />
          </li>
        );
      })}
    </ProductFeaturesTableContainer>
  );
};

export default ProductFeaturesTable;

import { FlexibleWidthDivider } from '@rsa-digital/evo-shared-components/components/Divider';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React, { MouseEventHandler } from 'react';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import { replacePlaceholdersPlainText } from 'helpers/placeholders/replaceCsPlaceholders';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import { Product } from 'helpers/productHelpers';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import usePercentageOfClaimsCovered from 'helpers/usePercentageOfClaimsCovered';
import { ExtraCoverFeeLimit, PremierCoverFeeLimit } from 'state/formData/quoteOptions';
import { CsIcon } from 'types/contentStack';
import {
  PayMonthlyPromoInfoCard,
  StyledDividerDesktop,
  StyledGridItemDesktop,
  StyledGridItemMobile,
  StyledPricePanel,
} from './styles';
import DonutChart from '../DonutChart';

type csPetAnnualPaymentProps = {
  csPetAnnualPayment: {
    pay_monthly_promo: {
      heading: string;
      body: string;
      icon: [CsIcon];
    };
  };
};

const query = graphql`
  query {
    csPetAnnualPayment {
      pay_monthly_promo {
        heading
        body
        icon {
          icon_code
        }
      }
    }
  }
`;

type PropductSummarySectionProps = {
  paymentTypeText: string;
  pricePanelCopy?: string;
  switchInstallmentTypeLink: string;
  isAnnualPayment: boolean;
  paymentOnClick: MouseEventHandler;
  product: Product;
  donutChartText: string;
  vetFeeLimit: ExtraCoverFeeLimit | PremierCoverFeeLimit | undefined;
  extraOrPremierQuote: boolean;
  standardOrExtraOrPremierQuote: boolean;
  productSummaryRef: React.RefObject<HTMLDivElement>;
};

const ProductSummarySection: React.FC<PropductSummarySectionProps> = ({
  paymentTypeText,
  pricePanelCopy,
  isAnnualPayment,
  switchInstallmentTypeLink,
  paymentOnClick,
  product,
  donutChartText,
  vetFeeLimit,
  extraOrPremierQuote,
  standardOrExtraOrPremierQuote,
  productSummaryRef,
}) => {
  const {
    csPetAnnualPayment: { pay_monthly_promo },
  } = useStaticQuery<csPetAnnualPaymentProps>(query);

  const quote = useCurrentQuote();
  const substitutePlaceholders = replacePlaceholdersPlainText(quotePlaceholders, quote);
  const annualPrice = formatInPoundsWithSign(quote?.price?.annualPrice.total);
  const monthlyPrice = formatInPoundsWithSign(quote?.price?.monthlyPrice.total);

  const coverPercentage = usePercentageOfClaimsCovered(
    product,
    vetFeeLimit || 0,
    quote.petInfos ?? []
  );

  const payMonthlyPromoIcon = unwrapSingleton(pay_monthly_promo.icon)?.icon_code;

  return (
    <Grid alignLeft ref={productSummaryRef}>
      <GridItem
        desktop={6}
        tabletLandscape={6}
        tabletPortrait={extraOrPremierQuote ? 5 : 6}>
        <StyledPricePanel
          data-cy="summaryPricePanel"
          summaryText={paymentTypeText}
          heading={isAnnualPayment ? annualPrice : monthlyPrice}
          copy={pricePanelCopy}
          link={{
            onClick: paymentOnClick,
            text: `${substitutePlaceholders(switchInstallmentTypeLink)}`,
          }}
        />
      </GridItem>
      {isAnnualPayment && (
        <StyledGridItemMobile desktop={6} tabletLandscape={6} tabletPortrait={6}>
          <PayMonthlyPromoInfoCard
            data-cy="annualPaymentSummaryPayMonthlyPromo"
            heading={pay_monthly_promo.heading}
            body={pay_monthly_promo.body}
            headerIcon={payMonthlyPromoIcon}
          />
          <FlexibleWidthDivider aria-hidden />
        </StyledGridItemMobile>
      )}
      {standardOrExtraOrPremierQuote && (
        <GridItem
          desktop={4}
          tabletLandscape={4}
          tabletPortrait={2}
          data-cy="claimsCoveredChart">
          <DonutChart
            coveredClaimsPercentage={coverPercentage}
            chartText={donutChartText}
          />
        </GridItem>
      )}
      {isAnnualPayment && (
        <>
          <StyledGridItemDesktop
            desktop={6.75}
            tabletLandscape={6.75}
            tabletPortrait={5.25}>
            <PayMonthlyPromoInfoCard
              data-cy="annualPaymentSummaryPayMonthlyPromo"
              heading={pay_monthly_promo.heading}
              body={pay_monthly_promo.body}
              headerIcon={payMonthlyPromoIcon}
            />
          </StyledGridItemDesktop>
          <StyledDividerDesktop />
        </>
      )}
    </Grid>
  );
};

export default ProductSummarySection;

import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';
import { StyledLinkWithIcon } from 'components/FormFooter/styles';
import RichTextWithModal from 'components/RichTextWithModal';

export const LargeRichTextWithModal = styled(RichTextWithModal)`
  p {
    ${fonts.paragraphLarge}
  }
  margin-top: ${spacing(4)};
  &:first-of-type {
    margin-top: 0;
  }
`;

export const BorderWrapper = styled.div<{
  isSecondSection?: boolean;
}>`
  border: solid 1px ${colors.neutral04};
  padding: ${spacing(4)};
  margin-top: ${(p) => (p.isSecondSection ? spacing(4) : 0)};
  width: 100%;

  ${mediaQuery.tabletLandscape`
    margin-top: 0;
    margin-left: ${(p) => (p.isSecondSection ? spacing(4) : 0)};
  `}
`;

export const SectionWrapper = styled(GridItem)``;

export const WrapperGrid = styled(Grid)``;

export const TwoExcessSectionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaQuery.tabletLandscape`
    flex-direction: row;
  `}
`;

export const ExcessSectionWrapper = styled.div<{
  removeBottomMargin?: boolean;
}>`
  background: ${colors.core02};
  padding: ${spacing(4)};
  margin-bottom: ${(p) => (p.removeBottomMargin ? 0 : spacing(4))};
`;
export const StyledPanel = styled.div`
  background-color: ${colors.neutral08};
  border-left: 4px ${colors.notificationInfo} solid;
  padding: ${spacing(3)};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(4)};
    padding-left: ${spacing(8.5)};
    `};
  margin-bottom: ${spacing(2)};
  display: flex;
  flex-direction: column;
`;
export const StyledPanelDescription = styled.p`
  ${fonts.paragraph}
  margin: ${spacing(0)}
`;
export const StyledPanelHeading = styled.h3`
  ${fonts.headingXSmall}
  margin-top: ${spacing(0)}
`;

export const StyledLinkWithIconWithNoMargin = styled(StyledLinkWithIcon)`
  margin-left: ${spacing(0)};
  margin-top: ${spacing(3)};
  text-decoration: none;
`;

import { dateValueToUtcDate } from '@rsa-digital/evo-shared-components/helpers/dateHelpers';
import { useEffect, useState } from 'react';
import { useOptimizelyGradualRevealExperimentVariation } from 'helpers/optimizelyVariation';
import { petType_CAT, petType_DOG } from 'helpers/referenceDataConstants';
import { useCustomerDetails } from 'state/formData/customerDetails';
import { usePetsDetails } from 'state/formData/petsDetails';

export const useValidateAboutYouForm = (): boolean => {
  const [petsDetails] = usePetsDetails();
  const isGradualRevealExperiment = useOptimizelyGradualRevealExperimentVariation();

  const [allPetsAreEligible, setAllPetsAreEligible] = useState<boolean>(false);
  const [allPetsAreHealthy, setAllPetsAreHealthy] = useState<boolean>(false);
  const [allPetsAreSprayed, setAllPetsAreSprayed] = useState<boolean>(false);
  const [allPetsAreChipped, setAllPetsAreChipped] = useState<boolean>(false);
  const [allPetsAreGenderFilled, setAllPetsAreGenderFilled] = useState<boolean>(false);
  const [allPetsCostFilled, setAllPetsCostFilled] = useState<boolean>(false);
  const [allPetsArePetBreedFilled, setAllPetsArePetBreedFilled] = useState<boolean>(
    false
  );
  const [allPetsDOBSet, setAllPetsDOBSet] = useState<boolean>(false);

  useEffect(() => {
    setAllPetsAreEligible(!petsDetails.some((pet) => pet.petName && !pet.petIsEligible));
    setAllPetsAreHealthy(!petsDetails.some((pet) => !pet.petInGoodHealth));
    setAllPetsAreSprayed(!petsDetails.some((pet) => pet.petSpayed === ''));
    setAllPetsAreChipped(!petsDetails.some((pet) => pet.petChipped === ''));
    setAllPetsAreGenderFilled(!petsDetails.some((pet) => pet.petGender === ''));
    setAllPetsAreGenderFilled(!petsDetails.some((pet) => pet.petGender === ''));
    setAllPetsArePetBreedFilled(
      !petsDetails.some(
        (pet) =>
          (pet.petType === petType_DOG && pet.dogBreedType === '') ||
          (pet.petType === petType_CAT && pet.catBreedType === '')
      )
    );
    setAllPetsCostFilled(!petsDetails.some((pet) => pet.petCost === undefined));
    setAllPetsDOBSet(!petsDetails.some((pet) => dateValueToUtcDate(pet.petDob) === null));
  }, [
    petsDetails,
    allPetsAreEligible,
    allPetsAreHealthy,
    allPetsAreSprayed,
    allPetsAreChipped,
    allPetsAreGenderFilled,
    allPetsArePetBreedFilled,
    allPetsCostFilled,
    allPetsDOBSet,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    (isGradualRevealExperiment &&
      allPetsAreEligible &&
      allPetsAreHealthy &&
      allPetsAreSprayed &&
      allPetsAreChipped &&
      allPetsAreGenderFilled &&
      allPetsArePetBreedFilled &&
      allPetsCostFilled &&
      allPetsDOBSet) ||
    (!isGradualRevealExperiment && allPetsAreEligible && allPetsAreHealthy)
  ) {
    return true;
  }
  return false;
};

export const useValidateAboutYourPolicyForm = (): boolean => {
  const [petsDetails] = usePetsDetails();
  const [customerDetails] = useCustomerDetails();
  const [allPetsAreEligible, setAllPetsAreEligible] = useState<boolean>(false);
  const [allPetsAreHealthy, setAllPetsAreHealthy] = useState<boolean>(false);

  const [customerDetailsFilled, setCustomerDetailsFilled] = useState<boolean>(false);
  const isGradualRevealExperiment = useOptimizelyGradualRevealExperimentVariation();

  useEffect(() => {
    const isAddressFormFilled = (): boolean => {
      if (
        (customerDetails.address?.postcode !== undefined &&
          customerDetails.postcode === '') ||
        (customerDetails.address?.postcode === undefined &&
          customerDetails.postcode !== '') ||
        (customerDetails.address?.postcode !== undefined &&
          customerDetails.postcode !== '')
      ) {
        return true;
      }

      return false;
    };

    setCustomerDetailsFilled(
      customerDetails.customerFirstName !== '' &&
        customerDetails.customerLastName !== '' &&
        customerDetails.customerTitle !== '' &&
        customerDetails.customerEmail !== '' &&
        customerDetails.customerTelephone !== '' &&
        isAddressFormFilled() &&
        dateValueToUtcDate(customerDetails.customerDob) !== null
    );
    setAllPetsAreEligible(!petsDetails.some((pet) => pet.petName && !pet.petIsEligible));
    setAllPetsAreHealthy(!petsDetails.some((pet) => !pet.petInGoodHealth));
  }, [
    petsDetails,
    customerDetails,
    allPetsAreEligible,
    allPetsAreHealthy,
    customerDetailsFilled,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  if (
    (isGradualRevealExperiment && customerDetailsFilled) ||
    (!isGradualRevealExperiment && allPetsAreEligible && allPetsAreHealthy)
  ) {
    return true;
  }
  return false;
};

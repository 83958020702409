import {
  required,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { QuoteOptions } from 'state/formData/quoteOptions';
import { isCheapestPremier, isStandard } from './helpers';

type CsAdditionalQuestionErrorMessages = {
  csPetQuoteSummaryOfYourNeeds: {
    additional_question_cheapest_premier: {
      error_messages: {
        missing: string;
        no_selected: string;
      };
    };
    additional_question_standard: {
      error_messages: {
        missing: string;
        no_selected: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeeds {
      additional_question_cheapest_premier {
        error_messages {
          missing
          no_selected
        }
      }
      additional_question_standard {
        error_messages {
          missing
          no_selected
        }
      }
    }
  }
`;

export const useAdditionalQuestionRules = (): ValidationRules<QuoteOptions> => {
  const {
    csPetQuoteSummaryOfYourNeeds: {
      additional_question_cheapest_premier,
      additional_question_standard,
    },
  } = useStaticQuery<CsAdditionalQuestionErrorMessages>(query);

  return {
    additionalQuestionCheapestPremier: validateIf(isCheapestPremier, [
      required(additional_question_cheapest_premier.error_messages.missing),
      {
        validityCondition: (value) => value !== false,
        errorMessage: additional_question_cheapest_premier.error_messages.no_selected,
      },
    ]),
    additionalQuestionStandard: validateIf(isStandard, [
      required(additional_question_standard.error_messages.missing),
      {
        validityCondition: (value) => value !== false,
        errorMessage: additional_question_standard.error_messages.no_selected,
      },
    ]),
  };
};

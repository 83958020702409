import {
  required,
  validateIf,
} from '@rsa-digital/evo-shared-components/helpers/forms/rules';
import { ValidationRules } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import {
  AccidentAndIllnessCoverDuration,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import { CsErrorsMissingOnly } from 'types/contentStack';

type CsQuoteSummaryDemandsAndNeedsErrorMessages = {
  csPetQuoteSummaryQuestions: {
    cover_type: CsErrorsMissingOnly;
    accident_and_illness_cover_duration: CsErrorsMissingOnly;
    extra_cover_fee_limits: CsErrorsMissingOnly;
    premier_cover_fee_limits: CsErrorsMissingOnly;
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryQuestions {
      extra_cover_fee_limits {
        error_messages {
          missing
        }
      }
      accident_and_illness_cover_duration {
        error_messages {
          missing
        }
      }
      premier_cover_fee_limits {
        error_messages {
          missing
        }
      }
    }
  }
`;

export const useQuoteSummaryDemandsAndNeedsRules = (): ValidationRules<QuoteOptions> => {
  const {
    csPetQuoteSummaryQuestions: {
      accident_and_illness_cover_duration,
      extra_cover_fee_limits,
      premier_cover_fee_limits,
    },
  } = useStaticQuery<CsQuoteSummaryDemandsAndNeedsErrorMessages>(query);

  const quote = useCurrentQuote();

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  const extraCoverLevel = (data: QuoteOptions): boolean =>
    data.accidentAndIllnessCoverDuration === AccidentAndIllnessCoverDuration.Until_Limit;

  const premierCoverLevel = (data: QuoteOptions): boolean =>
    data.accidentAndIllnessCoverDuration === AccidentAndIllnessCoverDuration.Ongoing;

  return {
    accidentAndIllnessCoverDuration: [
      required(
        plainTextPlaceholderReplacer(
          accident_and_illness_cover_duration.error_messages.missing
        )
      ),
    ],
    extraCoverFeeLimit: validateIf(extraCoverLevel, [
      required(
        plainTextPlaceholderReplacer(extra_cover_fee_limits.error_messages.missing)
      ),
    ]),
    premierCoverFeeLimit: validateIf(premierCoverLevel, [
      required(
        plainTextPlaceholderReplacer(premier_cover_fee_limits.error_messages.missing)
      ),
    ]),
  };
};

export default useQuoteSummaryDemandsAndNeedsRules;

import SimplePanel from '@rsa-digital/evo-shared-components/components/Panel/SimplePanel';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledPanel = styled(SimplePanel)`
  border: none;
  border-left: solid 4px ${colors.notificationInfo};
  background-color: ${colors.core02};
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.16);
  padding: ${spacing(3)};
  margin: ${spacing(4)} 0;
  display: flex;
  flex-direction: row;
`;

export const StyledText = styled.p`
  ${fonts.paragraphLarge}
  margin: 0;
`;

export const IconWrapper = styled.div`
  margin-right: ${spacing(2)};
`;

import { Divider } from '@rsa-digital/evo-shared-components/components/Divider/SimpleDivider/styles';
import RadioInput from '@rsa-digital/evo-shared-components/components/Form/RadioInput';
import { Orientation } from '@rsa-digital/evo-shared-components/components/Form/RadioInput/styles';
import SegmentedSelector from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector';
import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import LoadingOverlayV2 from '@rsa-digital/evo-shared-components/components/LoadingOverlayV2';
import PriceBanner from '@rsa-digital/evo-shared-components/components/PriceBanner';
import useValidation from '@rsa-digital/evo-shared-components/helpers/forms/useValidation';
import { isQuoteOptionSelectionValid } from 'apiHelpers/quote/bundleCoverMapping';
import useUpdateQuoteCover from 'apiHelpers/quote/useUpdateQuoteCover';
import { graphql, navigate } from 'gatsby';
import React, { useCallback, useReducer, useRef, useState } from 'react';
import AddAnotherPetModal from 'components/AddAnotherPetModal';
import AggregatorAgreements from 'components/Aggregators/AggregatorAgreements';
import AggregatorSummary from 'components/Aggregators/AggregatorSummary';
import LoadQuoteWrapper from 'components/ApiRequestWrapper/LoadQuoteWrapper';
import ConfirmationBox from 'components/ConfirmationBox';
import FormFooter from 'components/FormFooter';
import IconBanner from 'components/IconBanner';
import Layout from 'components/Layout';
import { BannerContainer } from 'components/Layout/styles';
import PricingOverviewPanel from 'components/PricingOverviewPanel';
import { QuoteAndBuyStep } from 'components/ProgressBar';
import QuestionField from 'components/QuestionField';
import ClubcardSection from 'components/QuoteSummary/ClubcardSection/index';
import ExcessSection from 'components/QuoteSummary/ExcessSection';
import PolicyDocumentsSection from 'components/QuoteSummary/PolicyDocumentsSection';
import { PriceBannerText } from 'components/QuoteSummary/PriceBannerText';
import SummaryOfCover from 'components/QuoteSummary/SummaryOfCover';
import AdditionalQuestion from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestion';
import {
  isCheapestPremier,
  isStandard,
} from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestion/helpers';
import { useAdditionalQuestionRules } from 'components/QuoteSummary/SummaryOfYourNeeds/AdditionalQuestion/validation';
import DiscountSection from 'components/QuoteSummary/SummaryOfYourNeeds/DiscountSection';
import InfoBoxSection from 'components/QuoteSummary/SummaryOfYourNeeds/InfoBoxSection';
import SummaryOfYourNeedsTextSection from 'components/QuoteSummary/SummaryOfYourNeeds/SummaryOfYourNeedsTextSection';
import { SessionExpiryOption } from 'components/SessionExpiry/SessionExpiryWrapper';
import TreatmentCostModal from 'components/TreatmentCostModal';
import useClubcardRules from 'forms/ClubcardForm/validation';
import SectionHeading from 'forms/SectionHeading';
import { addItem } from 'helpers/arrayHelper';
import { unwrapSingleton } from 'helpers/csTypeProcessors';
import {
  displayClubcardDiscount,
  displayMultipetDiscount,
  displayOnlineDiscount,
  useValidPricePanelOffers,
} from 'helpers/discountHelpers';
import { useCheckoutTracking } from 'helpers/ecommerceTracking';
import useDefaultErrorHandling from 'helpers/errorHandling';
import {
  PageTitle,
  trackCoverOptionsSelection,
  trackModalOpen,
  trackSwitchPaymentClick,
  trackTextButtonClick,
  trackVetFeeLimitSelection,
} from 'helpers/eventTracking';
import { TescoPageViewEventLevel } from 'helpers/eventTrackingForTesco';
import { scrollAndTrackError } from 'helpers/forms';
import { getPetTypesBeingInsured } from 'helpers/getPetTypesBeingInsured';
import { usePageTracking } from 'helpers/pageTracking';
import {
  useResetTescoPageLoadTrackingOnRefresh,
  useTescoPageLoadTracking,
  useTescoPageViewTracking,
} from 'helpers/pageTrackingForTesco';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
  replacePlaceholdersQuestion,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { formatInPoundsWithSign } from 'helpers/poundsHelpers';
import {
  getProductFromQuoteOptions,
  isCoinsuranceQuote,
  isQuoteFromAggs,
  Product,
} from 'helpers/productHelpers';
import { quoteAndBuyRoutes } from 'helpers/routingHelper';
import { trySaveQuote, useSaveAndEmailQuote } from 'helpers/saveQuoteHelpers';
import useAge8ExcessAgreements from 'helpers/useAge8ExcessAgreements';
import {
  CurrentQuote,
  useCurrentQuote,
  useUpdateQuoteOptions,
} from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import useScrollCallback from 'helpers/useScrollCallback';
import { Age8ExcessAgreements } from 'state/formData/age8ExcessAgreements';
import { ClubcardDetails, useCustomerDetails } from 'state/formData/customerDetails';
import { initialPet, usePetsDetails } from 'state/formData/petsDetails';
import {
  AccidentAndIllnessCoverDuration,
  CoverType,
  ExtraCoverFeeLimit,
  initialQuoteOptions,
  PremierCoverFeeLimit,
  QuoteOptions,
} from 'state/formData/quoteOptions';
import { useRetrieveQuoteDetails } from 'state/formData/retrieveQuote';
import { CsAsset, CsHero, CsIcon, CsModal, CsQuestion } from 'types/contentStack';
import {
  ConfirmationBoxWithMargin,
  LargeRichTextWithModalAndNoMargins,
  LargeTextParagraphWithSmallMargins,
  PriceBannerSpace,
  StyledGridItem,
  StyledParagraph,
  StyledPromotionalBox,
} from './styles';
import { useQuoteSummaryDemandsAndNeedsRules } from './validation';

const SCROLL_UPDATE_RATE_MS = 50;
const STEP = 3;

const findingTheRightCoverSectionId = 'finding-the-right-cover-section';
export const accidentAndIllnessCoverDurationRadioButtonId =
  'accidentAndIllnessCoverDurationRadioButton';
export const premierCoverFeeLimitsSelectorId = 'premierCoverFeeLimitsSelector';

type QuoteSummaryProps = {
  data: {
    csPetQuoteSummary: {
      meta_title: string;
      hero: CsHero;
      next_button_text: string;
      finding_the_right_cover_section_heading: string;
      finding_the_right_cover_section_description: string;
      standard_accident_and_illness_cover_confirmation_text: string;
      extra_accident_and_illness_cover_confirmation_text: string;
      premier_accident_and_illness_cover_confirmation_text: string;
      set_your_excess_section_heading: string;
      summary_of_your_needs_section_heading: string;
      renew_cover_reminder: string;
      successful_save_modal: [CsModal] | [];
      price_banner: {
        monthly_label: string;
        annual_label: string;
      };
      add_another_pet_promo_box: {
        icon: [CsIcon];
        heading: string;
        less_than_three_pets_information: string;
        three_pets_information: string;
        add_another_pet_button_text: string;
        add_another_pet_button_screenreader_text: string;
      };
      set_your_excess_section_description: string;
      icon_banner: {
        icon: [CsIcon];
        text: string;
      };
    };
    csPetQuoteSummaryQuestions: {
      accident_and_illness_cover_duration: CsQuestion;
      accident_and_illness_cover_type_radio_button_options: {
        standard_cover_button_text: string;
        extra_cover_button_text: string;
        premier_cover_button_text: string;
      };
      extra_cover_fee_limits: CsQuestion;
      premier_cover_fee_limits: CsQuestion;
    };
    csPetQuoteSummaryOfYourNeeds: {
      discounts: {
        online_discount_icon: CsAsset | null;
      };
    };
    csPetGlobalConfig: {
      loading_spinner: {
        email_spinner_text: string;
        excess_spinner_text: string;
        continue_spinner_text: string;
      };
    };
  };
};

export const query = graphql`
  query {
    csPetQuoteSummary {
      meta_title
      hero {
        heading
        subheading
      }
      finding_the_right_cover_section_heading
      finding_the_right_cover_section_description
      standard_accident_and_illness_cover_confirmation_text
      extra_accident_and_illness_cover_confirmation_text
      premier_accident_and_illness_cover_confirmation_text
      set_your_excess_section_heading
      summary_of_your_needs_section_heading
      renew_cover_reminder
      add_another_pet_promo_box {
        icon {
          icon_code
        }
        heading
        three_pets_information
        less_than_three_pets_information
        add_another_pet_button_text
        add_another_pet_button_screenreader_text
      }
      next_button_text
      successful_save_modal {
        modal_id
      }
      price_banner {
        monthly_label
        annual_label
      }
      set_your_excess_section_description
      icon_banner {
        icon {
          icon_code
        }
        text
      }
    }
    csPetQuoteSummaryQuestions {
      accident_and_illness_cover_duration {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      accident_and_illness_cover_type_radio_button_options {
        standard_cover_button_text
        extra_cover_button_text
        premier_cover_button_text
      }
      extra_cover_fee_limits {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
      premier_cover_fee_limits {
        main_details {
          question_text
          explanatory_text
          tooltip {
            tooltip_text
            tooltip_open
            tooltip_close
          }
          alert_text
        }
      }
    }
    csPetQuoteSummaryOfYourNeeds {
      discounts {
        online_discount_icon {
          ...CsAsset
        }
      }
    }
    csPetGlobalConfig {
      loading_spinner {
        email_spinner_text
        excess_spinner_text
        continue_spinner_text
      }
    }
  }
`;

const QuoteSummary: React.FC<QuoteSummaryProps> = ({
  data: {
    csPetQuoteSummary: {
      meta_title,
      hero,
      next_button_text,
      finding_the_right_cover_section_description,
      standard_accident_and_illness_cover_confirmation_text,
      extra_accident_and_illness_cover_confirmation_text,
      set_your_excess_section_heading,
      summary_of_your_needs_section_heading,
      premier_accident_and_illness_cover_confirmation_text,
      price_banner,
      renew_cover_reminder,
      add_another_pet_promo_box,
      set_your_excess_section_description,
      icon_banner,
    },
    csPetQuoteSummaryQuestions: {
      accident_and_illness_cover_duration,
      accident_and_illness_cover_type_radio_button_options: {
        standard_cover_button_text,
        extra_cover_button_text,
        premier_cover_button_text,
      },
      extra_cover_fee_limits,
      premier_cover_fee_limits,
    },
    csPetQuoteSummaryOfYourNeeds: {
      discounts: { online_discount_icon },
    },
    csPetGlobalConfig: {
      loading_spinner: { email_spinner_text, excess_spinner_text, continue_spinner_text },
    },
  },
}) => {
  const quote = useCurrentQuote();

  const updateQuoteOptions = useUpdateQuoteOptions();

  const quoteUpdaters = useUpdateQuoteCover();
  const summaryOfCoverSectionId = 'summary-of-cover-section';
  const excessSectionId = 'excess-section';
  const summaryOfYourNeedsSectionId = 'summary-of-your-needs-section';

  const quoteOptions = quote?.quoteOptions ?? initialQuoteOptions;

  const {
    coverType,
    accidentAndIllnessCoverDuration,
    extraCoverFeeLimit,
    premierCoverFeeLimit,
    isAnnualPayment,
  } = quoteOptions;

  const [showStickyBanner, setShowStickyBanner] = useState<boolean>(false);
  const summaryOfCoverRef = useRef<HTMLDivElement>(null);

  const [isContinueClicked, setIsContinueClicked] = useState<boolean>(false);

  const totalSelectedPrice = isAnnualPayment
    ? quote.price?.annualPrice.total
    : quote.price?.monthlyPrice.total;

  const petsInHousehold = quote.policyInfo?.numberPetInHousehold
    ? quote.policyInfo?.numberPetInHousehold
    : 1;
  const petsInQuote = quote.petInfos ? quote.petInfos.length : 1;

  const updateScrollCallback = useCallback(() => {
    if (!summaryOfCoverRef.current) {
      return;
    }
    setShowStickyBanner(!!totalSelectedPrice);
  }, [totalSelectedPrice]);

  useScrollCallback(updateScrollCallback, SCROLL_UPDATE_RATE_MS);

  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);

  const plainTextPlaceholderReplacer = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  const substituteQuoteQuestionPlaceholders = replacePlaceholdersQuestion(
    plainTextPlaceholderReplacer
  );

  const accidentAndIllnessCoverDurationQuestion = substituteQuoteQuestionPlaceholders(
    processQuestion(accident_and_illness_cover_duration)
  );

  const extraCoverFeeLimitsQuestion = substituteQuoteQuestionPlaceholders(
    processQuestion(extra_cover_fee_limits)
  );

  const premierCoverFeeLimitsQuestion = substituteQuoteQuestionPlaceholders(
    processQuestion(premier_cover_fee_limits)
  );

  const [showTreatmentCostModal, toggleTreatmentCostModal] = useReducer(
    (state) => !state,
    false
  );
  const product = getProductFromQuoteOptions(quote.quoteOptions);

  const getVetFeeLimit = (): number | undefined => {
    switch (product) {
      case Product.Extra:
        return extraCoverFeeLimit;
      case Product.Premier:
        return premierCoverFeeLimit;
      default:
        return undefined;
    }
  };

  const [showAddAnotherPetModal, toggleAddAnotherPetModal] = useReducer(
    (state) => !state,
    false
  );

  const paymentOnClick = (): void => {
    trackSwitchPaymentClick(
      PageTitle.QuoteSummary,
      isAnnualPayment ? 'monthly' : 'annually'
    );
    updateQuoteOptions({
      isAnnualPayment: !isAnnualPayment,
    });
  };
  const treatmentCostModalOnClick = (): void => {
    toggleTreatmentCostModal();
    trackModalOpen('treatmentCosts');
  };

  const numberOfPetsInQuote = quote.petInfos?.length || 0;

  const [petsDetails, updatePetsDetails] = usePetsDetails();
  const [customerDetails] = useCustomerDetails();
  const [retrieveQuoteDetails] = useRetrieveQuoteDetails();

  const displayAdditionalQuestion =
    isCheapestPremier(quote.quoteOptions) || isStandard(quote.quoteOptions);

  const defaultErrorHandling = useDefaultErrorHandling();

  const moveNext = async (): Promise<void> => {
    try {
      await trySaveQuote({
        onSuccess: () => navigate(quoteAndBuyRoutes.checkYourDetails),
        quoteUpdaters,
      });
    } catch (err) {
      const error: Error = err as Error;
      defaultErrorHandling(error);
    }
  };

  const {
    saveAndEmailQuote,
    isSaveInProgress,
    savedQuoteConfirmationModal,
  } = useSaveAndEmailQuote(PageTitle.QuoteSummary);

  const {
    age8ExcessAgreements,
    age8ExcessAgreementRules,
    updateAge8ExcessAgreements,
  } = useAge8ExcessAgreements();

  // TODO: Remove QuoteOptions
  const formDetails: CurrentQuote &
    QuoteOptions &
    ClubcardDetails &
    Age8ExcessAgreements = {
    ...quote,
    ...quote?.quoteOptions,
    ...customerDetails,
    ...age8ExcessAgreements,
  };

  const formErrorRules = useAdditionalQuestionRules();
  const isAggsQuote = isQuoteFromAggs(quote);
  const clubcardRules = useClubcardRules(PageTitle.QuoteSummary, isAggsQuote);
  const quoteOptionRules = useQuoteSummaryDemandsAndNeedsRules();

  const isCoinsurance = isCoinsuranceQuote;

  const showAddPetPromoBox =
    (coverType === CoverType.Accident_And_Illness &&
      petsInHousehold > petsInQuote &&
      petsInQuote < 3) ||
    (isAggsQuote && petsInQuote === 1 && petsInHousehold === 1);

  /* We use the lookupKey condition here so the form doesn't disappear if data has been entered into
   * it before a successful clubcard lookup is achieved
   */
  const hideClubcardSectionForRetrieveQuote =
    retrieveQuoteDetails.quoteNumber !== '' &&
    customerDetails.clubcardLookup.lookupKey !== '';

  const hideClubcardSectionForAnswerNo =
    !customerDetails.hasClubCardUpdatedInQS &&
    customerDetails.hasClubcard === 'no' &&
    !isAggsQuote;

  const shouldShowClubcardSection =
    isAggsQuote ||
    customerDetails.hasClubcard === 'askLater' ||
    customerDetails.clubcardSearch.status !== 'SUCCESS' ||
    customerDetails.clubcardLookup.lookupKey !== '';

  const pricePanelOffers = useValidPricePanelOffers();
  const displayPricePanelOffers = !!pricePanelOffers && pricePanelOffers.length > 0;

  const shouldDisplayPricePanelDiscounts =
    displayClubcardDiscount(quote) ||
    displayMultipetDiscount(quote) ||
    displayOnlineDiscount(online_discount_icon) ||
    displayPricePanelOffers;

  const { getError, showValidation, resetValidation, validateOnSubmit } = useValidation(
    formDetails,
    {
      ...(isCoinsurance ? age8ExcessAgreementRules : {}),
      ...formErrorRules,
      ...clubcardRules,
      ...quoteOptionRules,
    }
  );

  usePageTracking(meta_title, !!quote.customerInfo);
  useTescoPageLoadTracking();
  useResetTescoPageLoadTrackingOnRefresh();
  useTescoPageViewTracking(TescoPageViewEventLevel.extended);
  useCheckoutTracking(STEP, quote, true);

  const loadingMsgText = (): string => {
    if (isSaveInProgress) return email_spinner_text;
    if (isContinueClicked) return continue_spinner_text;
    return excess_spinner_text;
  };

  const bannerIcon = unwrapSingleton(icon_banner.icon);
  /* istanbul ignore if */
  if (!bannerIcon) {
    throw new Error('Icon for icon banner missing!');
  }

  return (
    <LoadQuoteWrapper>
      <Layout
        metaTitle={meta_title}
        currentStep={QuoteAndBuyStep.QuoteSummary}
        pageTitle={PageTitle.QuoteSummary}
        sessionExpiryOption={
          isQuoteOptionSelectionValid(quoteOptions)
            ? SessionExpiryOption.EMAIL_QUOTE_SESSION_EXPIRY
            : undefined
        }
        heading={hero.heading}
        subheading={hero.subheading}>
        {(quoteUpdaters?.isLoading || isSaveInProgress) && (
          <LoadingOverlayV2 loadingMessage={loadingMsgText()} timeDuration={10} />
        )}
        <form onSubmit={validateOnSubmit(moveNext, scrollAndTrackError)}>
          {isAggsQuote && (
            <AggregatorSummary
              aggregator={quote.productId}
              pageTitle={PageTitle.QuoteSummary}
            />
          )}
          <section
            aria-labelledby={findingTheRightCoverSectionId}
            data-cy="coverTypeSection">
            <StyledGridItem desktop={8} tabletLandscape={6} tabletPortrait={6}>
              <StyledParagraph>
                {finding_the_right_cover_section_description}
              </StyledParagraph>
            </StyledGridItem>
            <QuestionField
              question={accidentAndIllnessCoverDurationQuestion}
              errorText={getError('accidentAndIllnessCoverDuration')}>
              <RadioInput
                id="accidentAndIllnessCoverDurationRadioButton"
                value={accidentAndIllnessCoverDuration}
                options={[
                  {
                    name: standard_cover_button_text,
                    value: AccidentAndIllnessCoverDuration.Short_Term,
                  },
                  {
                    name: extra_cover_button_text,
                    value: AccidentAndIllnessCoverDuration.Until_Limit,
                  },
                  {
                    name: premier_cover_button_text,
                    value: AccidentAndIllnessCoverDuration.Ongoing,
                  },
                ]}
                onChange={(e) => {
                  updateQuoteOptions({
                    accidentAndIllnessCoverDuration: e.target
                      .value as AccidentAndIllnessCoverDuration,
                  });
                  switch (e.target.value) {
                    case AccidentAndIllnessCoverDuration.Short_Term:
                      trackCoverOptionsSelection('Short term');
                      break;
                    case AccidentAndIllnessCoverDuration.Until_Limit:
                      trackCoverOptionsSelection('Limit reached');
                      break;
                    case AccidentAndIllnessCoverDuration.Ongoing:
                      trackCoverOptionsSelection('Ongoing');
                      break;
                    default:
                  }
                  resetValidation('petUnder8');
                  resetValidation('petOver8');
                }}
              />
            </QuestionField>
            {accidentAndIllnessCoverDuration ===
              AccidentAndIllnessCoverDuration.Short_Term && (
              <Grid alignLeft>
                <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
                  <ConfirmationBox
                    pageTitle={PageTitle.QuoteSummary}
                    id="standardCoverConfirmationBox"
                    html={standard_accident_and_illness_cover_confirmation_text}
                  />
                </GridItem>
              </Grid>
            )}
            {accidentAndIllnessCoverDuration ===
              AccidentAndIllnessCoverDuration.Until_Limit && (
              <>
                <Grid alignLeft>
                  <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
                    <ConfirmationBoxWithMargin
                      pageTitle={PageTitle.QuoteSummary}
                      id="extraCoverConfirmationBox"
                      html={extra_accident_and_illness_cover_confirmation_text}
                    />
                  </GridItem>
                </Grid>
                <QuestionField
                  question={extraCoverFeeLimitsQuestion}
                  errorText={getError('extraCoverFeeLimit')}>
                  <RadioInput
                    id="extraCoverFeeLimitsRadioButton"
                    orientationOverride={Orientation.horizontal}
                    value={extraCoverFeeLimit?.toString()}
                    options={[
                      { name: '£4,000', value: '4000' },
                      {
                        name: '£7,500',
                        value: '7500',
                      },
                    ]}
                    onChange={(e) => {
                      updateQuoteOptions({
                        extraCoverFeeLimit: parseInt(
                          e.target.value,
                          10
                        ) as ExtraCoverFeeLimit,
                      });
                      trackVetFeeLimitSelection(e.target.value);
                      resetValidation('petUnder8');
                      resetValidation('petOver8');
                    }}
                  />
                </QuestionField>
              </>
            )}
            {accidentAndIllnessCoverDuration ===
              AccidentAndIllnessCoverDuration.Ongoing && (
              <>
                <Grid alignLeft>
                  <GridItem desktop={6} tabletLandscape={6} tabletPortrait={6}>
                    <ConfirmationBoxWithMargin
                      pageTitle={PageTitle.QuoteSummary}
                      html={premier_accident_and_illness_cover_confirmation_text}
                    />
                  </GridItem>
                </Grid>
                <QuestionField
                  question={premierCoverFeeLimitsQuestion}
                  errorText={getError('premierCoverFeeLimit')}>
                  <SegmentedSelector
                    id={premierCoverFeeLimitsSelectorId}
                    value={premierCoverFeeLimit?.toString()}
                    shownOptions={4}
                    options={[
                      { name: '£2,000', value: '2000' },
                      { name: '£4,000', value: '4000' },
                      {
                        name: '£7,500',
                        value: '7500',
                      },
                      { name: '£10,000', value: '10000' },
                    ]}
                    onChange={(e) => {
                      updateQuoteOptions({
                        premierCoverFeeLimit: parseInt(
                          e.target.value,
                          10
                        ) as PremierCoverFeeLimit,
                      });
                      trackVetFeeLimitSelection(e.target.value);
                      resetValidation('petUnder8');
                      resetValidation('petOver8');
                    }}
                  />
                </QuestionField>
              </>
            )}
          </section>
          {isQuoteOptionSelectionValid(quoteOptions) && product && (
            <>
              <section aria-label={summaryOfCoverSectionId}>
                <SummaryOfCover
                  data-cy="summaryOfCover"
                  isAnnualPayment={isAnnualPayment}
                  paymentOnClick={paymentOnClick}
                  product={product}
                  insuredPetTypes={getPetTypesBeingInsured(quote?.petInfos || [])}
                  treatmentCostOnClick={treatmentCostModalOnClick}
                  productSummaryRef={summaryOfCoverRef}
                  pageTitle={PageTitle.QuoteSummary}
                  vetFeeLimit={getVetFeeLimit()}
                />
                <LargeTextParagraphWithSmallMargins>
                  {renew_cover_reminder}
                </LargeTextParagraphWithSmallMargins>
                {showAddPetPromoBox && (
                  <Grid alignLeft>
                    <GridItem desktop={10} tabletLandscape={10}>
                      <StyledPromotionalBox
                        pageTitle={PageTitle.QuoteSummary}
                        id="add-another-pet-promotional-box"
                        icon={unwrapSingleton(add_another_pet_promo_box.icon)?.icon_code}
                        heading={add_another_pet_promo_box.heading}
                        information={
                          numberOfPetsInQuote < 3
                            ? add_another_pet_promo_box.less_than_three_pets_information
                            : add_another_pet_promo_box.three_pets_information
                        }
                        button={
                          numberOfPetsInQuote < 3
                            ? {
                                buttonText:
                                  add_another_pet_promo_box.add_another_pet_button_text,
                                screenReaderText:
                                  add_another_pet_promo_box.add_another_pet_button_text,
                                onClick: () => {
                                  toggleAddAnotherPetModal();
                                  trackModalOpen('addAnotherPet');
                                },
                              }
                            : undefined
                        }
                      />
                    </GridItem>
                  </Grid>
                )}
              </section>
              {shouldShowClubcardSection &&
                !hideClubcardSectionForRetrieveQuote &&
                !hideClubcardSectionForAnswerNo && (
                  <Grid alignLeft>
                    <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8}>
                      <ClubcardSection
                        formValidation={{ getError, showValidation }}
                        pageTitle={PageTitle.QuoteSummary}
                        isAggsQuote={isAggsQuote}
                      />
                    </GridItem>
                  </Grid>
                )}
              <section aria-labelledby={excessSectionId} data-cy="excessSection">
                <SectionHeading
                  heading={set_your_excess_section_heading}
                  id={excessSectionId}
                />
                <Grid alignLeft>
                  <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8}>
                    <LargeRichTextWithModalAndNoMargins
                      html={set_your_excess_section_description}
                      pageTitle={PageTitle.QuoteSummary}
                    />
                    <IconBanner text={icon_banner.text} icon={bannerIcon.icon_code} />
                  </GridItem>
                </Grid>
                <ExcessSection
                  quoteUpdaters={quoteUpdaters}
                  pageTitle={PageTitle.QuoteSummary}
                  age8ExcessAgreements={age8ExcessAgreements}
                  updateAge8ExcessAgreements={(
                    update: Age8ExcessAgreements,
                    group: 'petOver8' | 'petUnder8'
                  ) => {
                    updateAge8ExcessAgreements(update);
                    showValidation(group);
                  }}
                  getError={getError}
                />
              </section>
              <section aria-labelledby={summaryOfYourNeedsSectionId}>
                <SectionHeading
                  heading={summary_of_your_needs_section_heading}
                  id={summaryOfYourNeedsSectionId}
                />
                <SummaryOfYourNeedsTextSection pageTitle={PageTitle.QuoteSummary} />
                {displayAdditionalQuestion && (
                  <AdditionalQuestion
                    formValidation={{ getError }}
                    pageTitle={PageTitle.QuoteSummary}
                  />
                )}
                {!displayAdditionalQuestion && <Divider />}
                <PolicyDocumentsSection
                  quoteOptions={quoteOptions}
                  pageTitle={PageTitle.QuoteSummary}
                />
                <InfoBoxSection />
                <section aria-label="Payment Overview">
                  {shouldDisplayPricePanelDiscounts && (
                    <DiscountSection data-cy="quoteSummaryDiscounts" />
                  )}
                  <PricingOverviewPanel
                    data-cy="pricingOverviewPanel"
                    pageTitle={PageTitle.QuoteSummary}
                    paymentOnClick={paymentOnClick}
                  />
                </section>
              </section>
              {isAggsQuote && <AggregatorAgreements pageTitle={PageTitle.QuoteSummary} />}
            </>
          )}
          <FormFooter
            backButton={{
              onClick: () => {
                trackTextButtonClick(PageTitle.QuoteSummary, 'Back');
                navigate(quoteAndBuyRoutes.aboutYouAndYourPet);
              },
            }}
            moveNextButton={{
              text: next_button_text,
              onClick: () => {
                trackTextButtonClick(PageTitle.QuoteSummary, 'Continue');
                setIsContinueClicked(true);
              },
            }}
            saveButton={
              isQuoteOptionSelectionValid(quoteOptions)
                ? {
                    onClick: saveAndEmailQuote,
                  }
                : undefined
            }
            printButton={
              isQuoteOptionSelectionValid(quoteOptions)
                ? {
                    onClick: () => window.print(),
                  }
                : undefined
            }
            pageTitle={PageTitle.QuoteSummary}
          />
        </form>
        {savedQuoteConfirmationModal}
        {showTreatmentCostModal && (
          <TreatmentCostModal
            onClose={toggleTreatmentCostModal}
            pageTitle={PageTitle.QuoteSummary}
          />
        )}
        {showAddAnotherPetModal && (
          <AddAnotherPetModal
            data-cy="addAnotherPetModal"
            onClose={toggleAddAnotherPetModal}
            onConfirm={() => {
              updatePetsDetails(addItem(petsDetails, initialPet));
              navigate(
                `${quoteAndBuyRoutes.aboutYouAndYourPet}#petDetails[${numberOfPetsInQuote}]`
              );
              toggleAddAnotherPetModal();
            }}
            pageTitle={PageTitle.QuoteSummary}
          />
        )}
        {totalSelectedPrice && (
          <BannerContainer>
            <PriceBanner
              show={showStickyBanner}
              price={formatInPoundsWithSign(totalSelectedPrice)}
              priceLabel={
                isAnnualPayment ? price_banner.annual_label : price_banner.monthly_label
              }
              leftHandComponent={<PriceBannerText />}
            />
          </BannerContainer>
        )}
      </Layout>
      {showStickyBanner && <PriceBannerSpace />}
    </LoadQuoteWrapper>
  );
};

export default QuoteSummary;

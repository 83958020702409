import SegmentedSelector from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector';
import { StyledSegment } from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector/Segment/styles';
import { RadioButtonContainer } from '@rsa-digital/evo-shared-components/components/Form/SegmentedSelector/styles';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled from 'styled-components';

export const StyledSegmentedSelector = styled(SegmentedSelector)`
  ${RadioButtonContainer} {
    flex-direction: column;
    ${StyledSegment} {
      margin: ${spacing(1)} 0 0 0;
      &:first-of-type {
        margin: 0;
      }
    }
  }

  ${mediaQuery.tabletPortrait`
    ${RadioButtonContainer} {
      flex-direction: row;
      ${StyledSegment} {
        margin: 0 0 0 ${spacing(1)};
        &:first-of-type {
          margin: 0;
        }
      }
    }
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const SubheadingSmall = styled.p`
  ${fonts.headingSmall}
  margin: 0;
`;

export const ExplanatoryText = styled.p`
  ${fonts.paragraphLarge}
  margin: ${spacing(4)} 0;
`;

import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const Divider = styled.hr`
  border: none;
  background-color: ${colors.core01};
  height: 1px;
  margin: ${spacing(1)} 0;
`;

export const StyledPanel = styled.div<{ addBottomMargin?: boolean }>`
  border: none;
  background-color: ${colors.notificationInfoLight};
  margin-top: ${spacing(4)};
  margin-bottom: ${(p) => (p.addBottomMargin ? spacing(4) : 0)};
  padding: ${spacing(4)};
  display: flex;
  flex-direction: column;
`;

export const StyledRichText = styled(RichTextWithModal)`
  ${RichText} {
    p {
      ${fonts.paragraphLarge}
      margin-top: ${spacing(1)};
    }

    & > *:first-child {
      margin-top: 0;
    }
    & {
      font-weight: ${lightFontWeight};
    }
  }
`;

export const SubheadingRichText = styled(RichText)`
  p {
    ${fonts.headingXSmall}
    margin: 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  ${mediaQuery.tabletPortrait`
    flex-direction: row;
  `}
`;

export const ExampleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: ${spacing(4)};
  width: 100%;
  ${mediaQuery.tabletPortrait`
    width: 45%;
  `}
`;

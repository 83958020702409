import { getOptimizelyVariation } from 'helpers/optimizely';

const optimizelyGradualRevealExperimentVariation = getOptimizelyVariation(
  process.env.GATSBY_OPTIMIZELY_GRADUAL_REVEAL_EXPERIMENT
);

export const useOptimizelyGradualRevealExperimentVariation = (): boolean => {
  if (optimizelyGradualRevealExperimentVariation === 'Variation #1') {
    return true;
  }
  return false;
};

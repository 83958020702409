import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { getCoverStartDate } from 'businessLogic/getCoverStartDate';
import { isPet8YearsOrOlderOnDate } from 'businessLogic/petAge';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import {
  ExcessPlaceholderData,
  excessPlaceholders,
} from 'helpers/placeholders/excessPlaceholders';
import { petPlaceholders } from 'helpers/placeholders/petPlaceholders';
import { replacePlaceholdersRichText } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import { MarginlessLargeRichTextWithModal } from '../styles';

type CoinsuranceExcessStatementProps = {
  pageTitle: PageTitle;
  petInfo: QuoteResponsePetInfo;
  placeholderData: ExcessPlaceholderData;
  // eslint-disable-next-line react/no-unused-prop-types
  textAfterStatement?: string;
};

type CsSummaryOfNeedsTextSection = {
  csPetQuoteSummaryOfYourNeeds: {
    text_summary: {
      per_pet_excess_statement: string;
      coinsurance: {
        under_8_per_pet_percentage_excess_statement: string;
        above_8_per_pet_percentage_excess_statement: string;
      };
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeeds {
      text_summary {
        per_pet_excess_statement
        coinsurance {
          under_8_per_pet_percentage_excess_statement
          above_8_per_pet_percentage_excess_statement
        }
      }
    }
  }
`;

const CoinsuranceExcessStatement: React.FC<CoinsuranceExcessStatementProps> = ({
  pageTitle,
  petInfo,
  placeholderData,
}) => {
  const {
    per_pet_excess_statement,
    coinsurance: {
      under_8_per_pet_percentage_excess_statement,
      above_8_per_pet_percentage_excess_statement,
    },
  } = useStaticQuery<CsSummaryOfNeedsTextSection>(
    query
  ).csPetQuoteSummaryOfYourNeeds.text_summary;

  const substituteFixedExcessStatementPetPlaceholders = (
    pet: QuoteResponsePetInfo
  ): string =>
    replacePlaceholdersRichText(petPlaceholders, pet)(per_pet_excess_statement);

  const substituteAdditionalExcessStatementPetPlaceholders = (
    excessPlaceholderData: ExcessPlaceholderData,
    substituteText: string
  ): string =>
    replacePlaceholdersRichText(excessPlaceholders, placeholderData)(substituteText);

  const quote = useCurrentQuote();

  const additionalExcessStatement = isPet8YearsOrOlderOnDate(
    petInfo.dob,
    getCoverStartDate(quote.policyInfo?.coverStartDate)
  )
    ? above_8_per_pet_percentage_excess_statement
    : under_8_per_pet_percentage_excess_statement;

  return (
    <>
      <>
        <MarginlessLargeRichTextWithModal
          pageTitle={pageTitle}
          html={substituteFixedExcessStatementPetPlaceholders(petInfo)}
        />
        <MarginlessLargeRichTextWithModal
          pageTitle={pageTitle}
          html={substituteAdditionalExcessStatementPetPlaceholders(
            placeholderData,
            additionalExcessStatement
          )}
        />
      </>
    </>
  );
};

export default CoinsuranceExcessStatement;

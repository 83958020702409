import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import ExampleBox from 'components/ExampleBox';
import {
  AGE_8_EXCESS_PERCENTAGE,
  VoluntaryExcessPercentage,
} from 'helpers/businessConstants';
import { PageTitle } from 'helpers/eventTracking';
import {
  ExcessPlaceholderData,
  excessPlaceholders,
} from 'helpers/placeholders/excessPlaceholders';
import { quotePlaceholders } from 'helpers/placeholders/quotePlaceholders';
import {
  noOpPlaceholderReplacer,
  replacePlaceholdersPlainText,
  replacePlaceholdersRichText,
} from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useCurrentVetFeeLimit from 'helpers/useCurrentVetFeeLimit';
import { QuestionFieldWithoutMarginBottom, StyledRichText } from './styles';
import { SubheadingSmall } from '../AdditionalExcess/styles';

type Age8ExcessSectionProps = {
  pageTitle: PageTitle;
  petIndexes: number[] | undefined;
  age8ExcessAgreement: boolean | undefined;
  updateAge8ExcessAgreements: (update: boolean) => void;
  error?: string;
  groupIs8YearsOrOlder: boolean;
};

type CsChooseExcessSection = {
  csPetQuoteSummaryChooseExcess: {
    additional_vet_excess: {
      average_claim_value: number;
    };
    age_8_excess: {
      pet_under_8_years: {
        section_heading: string;
        section_description: string;
        age_8_excess_agreement_questions: {
          voluntary_excess_20_percent_question: string;
          voluntary_excess_under_20_percent_question: string;
        };
        how_your_excess_will_work: string;
        excess_examples: {
          excess_example_average_claim: string;
          excess_example_average_claim_payment: string;
          excess_example_large_claim: string;
          excess_example_large_claim_payment: string;
        };
      };
      pet_over_8_years: {
        section_heading: string;
        section_description: string;
        age_8_excess_agreement_question: string;
        how_your_excess_will_work: string;
        excess_examples: {
          excess_example_average_claim: string;
          excess_example_average_claim_payment: string;
          excess_example_large_claim: string;
          excess_example_large_claim_payment: string;
        };
      };
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcess {
      additional_vet_excess {
        average_claim_value
      }
      age_8_excess {
        pet_under_8_years {
          section_heading
          section_description
          age_8_excess_agreement_questions {
            voluntary_excess_20_percent_question
            voluntary_excess_under_20_percent_question
          }
          how_your_excess_will_work
          excess_examples {
            excess_example_average_claim
            excess_example_average_claim_payment
            excess_example_large_claim
            excess_example_large_claim_payment
          }
        }
        pet_over_8_years {
          section_heading
          section_description
          age_8_excess_agreement_question
          how_your_excess_will_work
          excess_examples {
            excess_example_average_claim
            excess_example_average_claim_payment
            excess_example_large_claim
            excess_example_large_claim_payment
          }
        }
      }
    }
  }
`;

const Age8Excess: React.FC<Age8ExcessSectionProps> = ({
  pageTitle,
  petIndexes,
  age8ExcessAgreement,
  updateAge8ExcessAgreements,
  error,
  groupIs8YearsOrOlder,
}) => {
  const {
    additional_vet_excess: { average_claim_value },
    age_8_excess: { pet_under_8_years, pet_over_8_years },
  } = useStaticQuery<CsChooseExcessSection>(query).csPetQuoteSummaryChooseExcess;

  const csContent = groupIs8YearsOrOlder ? pet_over_8_years : pet_under_8_years;
  const quote = useCurrentQuote();
  const petInfos = petIndexes ? quote.petInfos?.[petIndexes[petIndexes.length - 1]] : '';

  const excessPlaceholderData: ExcessPlaceholderData = {
    fixedExcess: petInfos ? parseInt(petInfos.voluntaryExcessAmount, 10) : 0,
    percentageExcess: AGE_8_EXCESS_PERCENTAGE,
    averageClaimValue: average_claim_value,
    vetFeeLimit: useCurrentVetFeeLimit(quote.quoteOptions) || '',
  };

  const replaceExcessPlaceholders = replacePlaceholdersRichText(
    excessPlaceholders,
    excessPlaceholderData
  );

  const substituteName = quote
    ? replacePlaceholdersPlainText(quotePlaceholders, quote, true)
    : noOpPlaceholderReplacer;

  const voluntaryExcessIs20Percent = petInfos
    ? petInfos?.voluntaryExcessPercentage === VoluntaryExcessPercentage.EXCESS_20_PERCENT
    : false;

  const getAge8ExcessAgreementQuestionText = (): string => {
    if (groupIs8YearsOrOlder) {
      return pet_over_8_years.age_8_excess_agreement_question;
    }

    return voluntaryExcessIs20Percent
      ? pet_under_8_years.age_8_excess_agreement_questions
          .voluntary_excess_20_percent_question
      : pet_under_8_years.age_8_excess_agreement_questions
          .voluntary_excess_under_20_percent_question;
  };

  return (
    <>
      <SubheadingSmall>{substituteName(csContent.section_heading)}</SubheadingSmall>
      <Grid alignLeft>
        <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8}>
          <StyledRichText html={csContent.section_description} pageTitle={pageTitle} />
          <Grid alignLeft>
            <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8}>
              <ExampleBox
                id={`age8ExcessExampleBox${
                  groupIs8YearsOrOlder ? 'PetOver8' : 'PetUnder8'
                }`}
                pageTitle={pageTitle}
                subheading={csContent.how_your_excess_will_work}
                averageClaimText={replaceExcessPlaceholders(
                  csContent.excess_examples.excess_example_average_claim
                )}
                averageClaimPayment={replaceExcessPlaceholders(
                  csContent.excess_examples.excess_example_average_claim_payment
                )}
                largeClaimText={replaceExcessPlaceholders(
                  csContent.excess_examples.excess_example_large_claim
                )}
                largeClaimPayment={replaceExcessPlaceholders(
                  csContent.excess_examples.excess_example_large_claim_payment
                )}
                addBottomMargin
              />
            </GridItem>
          </Grid>
        </GridItem>
        <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8}>
          <QuestionFieldWithoutMarginBottom
            question={{
              questionText: getAge8ExcessAgreementQuestionText(),
            }}
            errorText={error}
            gridItemProps={{ desktop: 10, tabletLandscape: 10, tabletPortrait: 8 }}>
            <BooleanRadioInput
              id={groupIs8YearsOrOlder ? 'petOver8' : 'petUnder8'}
              value={age8ExcessAgreement}
              onChange={updateAge8ExcessAgreements}
            />
          </QuestionFieldWithoutMarginBottom>
        </GridItem>
      </Grid>
    </>
  );
};

export default Age8Excess;

import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { scrollAndFocusInput } from '@rsa-digital/evo-shared-components/helpers/forms/scrollAndFocusError';
import { FieldFunction } from '@rsa-digital/evo-shared-components/helpers/forms/types';
import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import BooleanRadioInput from 'components/BooleanRadioInput';
import RichTextLarge from 'components/RichTextLarge';
import { PageTitle } from 'helpers/eventTracking';
import { useCurrentQuote, useUpdateQuoteOptions } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import {
  accidentAndIllnessCoverDurationRadioButtonId,
  premierCoverFeeLimitsSelectorId,
} from 'pages/pet/quote-summary';
import { QuoteOptions } from 'state/formData/quoteOptions';
import { CsQuestion } from 'types/contentStack';
import { Question } from 'types/forms';
import { isStandard } from './helpers';
import { AdditionalQuestionContainer, StyledQuestionField } from './styles';

type SummaryOfYourNeedsAdditionalQuestions = {
  csPetQuoteSummaryOfYourNeeds: {
    additional_question_cheapest_premier: {
      no_button_text: string;
      yes_button_text: string;
      question_details: string;
      question_field: CsQuestion;
    };
    additional_question_standard: {
      no_button_text: string;
      yes_button_text: string;
      question_details: string;
      question_field: CsQuestion;
    };
  };
};

type AdditionalQuestionProps = {
  formValidation: {
    getError: FieldFunction<QuoteOptions, string | undefined>;
  };
  pageTitle: PageTitle;
};

const query = graphql`
  query {
    csPetQuoteSummaryOfYourNeeds {
      additional_question_cheapest_premier {
        no_button_text
        question_details
        yes_button_text
        question_field {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
          }
        }
      }
      additional_question_standard {
        no_button_text
        question_details
        question_field {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
          }
        }
        yes_button_text
      }
    }
  }
`;

type AdditionalQuestionFields = {
  no_button_text: string;
  yes_button_text: string;
  question_details: string;
  question_field: CsQuestion;
};

const AdditionalQuestion: React.FC<AdditionalQuestionProps> = ({
  formValidation: { getError },
  pageTitle,
}) => {
  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);
  const quote = useCurrentQuote();
  const updateQuoteOptions = useUpdateQuoteOptions();

  const {
    csPetQuoteSummaryOfYourNeeds: {
      additional_question_cheapest_premier,
      additional_question_standard,
    },
  } = useStaticQuery<SummaryOfYourNeedsAdditionalQuestions>(query);

  const productIsStandard = isStandard(quote.quoteOptions);

  let csAdditionalQuestion: AdditionalQuestionFields;
  let additionalQuestion: Question;
  let questionId:
    | 'coverType'
    | 'accidentAndIllnessCoverDuration'
    | 'extraCoverFeeLimit'
    | 'premierCoverFeeLimit'
    | 'isAnnualPayment'
    | 'additionalQuestionCheapestPremier'
    | 'additionalQuestionStandard';
  let inputValue: boolean | undefined;
  let idOfScrolledToQuestion: string;

  if (productIsStandard) {
    csAdditionalQuestion = additional_question_standard;
    additionalQuestion = processQuestion(additional_question_standard.question_field);
    questionId = 'additionalQuestionStandard';
    inputValue = quote.quoteOptions?.additionalQuestionStandard;
    idOfScrolledToQuestion = accidentAndIllnessCoverDurationRadioButtonId;
  } else {
    // It must be the Cheapest Premier option
    csAdditionalQuestion = additional_question_cheapest_premier;
    additionalQuestion = processQuestion(
      additional_question_cheapest_premier.question_field
    );
    questionId = 'additionalQuestionCheapestPremier';
    inputValue = quote.quoteOptions?.additionalQuestionCheapestPremier;
    idOfScrolledToQuestion = premierCoverFeeLimitsSelectorId;
  }

  return (
    <>
      <Grid alignLeft>
        <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8}>
          <AdditionalQuestionContainer standardStyleRequired={productIsStandard}>
            <RichTextLarge
              html={csAdditionalQuestion.question_details}
              pageTitle={pageTitle}
            />
            <StyledQuestionField
              errorText={getError(questionId)}
              question={additionalQuestion}>
              <BooleanRadioInput
                id={questionId}
                value={inputValue}
                onChange={(newValue) => {
                  updateQuoteOptions({
                    [questionId]: newValue,
                  });
                  if (!newValue) {
                    scrollAndFocusInput(idOfScrolledToQuestion);
                  }
                }}
                yesLabel={csAdditionalQuestion.yes_button_text}
                noLabel={csAdditionalQuestion.no_button_text}
              />
            </StyledQuestionField>
          </AdditionalQuestionContainer>
        </GridItem>
      </Grid>
    </>
  );
};

export default AdditionalQuestion;

import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { gridItemPropsOverride } from '@rsa-digital/evo-shared-components/components/Grid/GridItem/styles';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { heavyFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled, { css } from 'styled-components';
import QuestionField from 'components/QuestionField';

// At the moment, additional_question_standard requires a special style which is different from the style of the other questions.
// A custom prop is thefore used to apply a different style to that question.
// Reference: https://styled-components.com/docs/api#using-custom-props

type AdditionalQuestionProps = {
  standardStyleRequired: boolean;
};

const standardQuestionStyle = css`
  p:nth-child(2) {
    margin-top: ${spacing(2)};
  }

  p:nth-child(3) {
    margin-top: ${spacing(1)};
  }
`;

export const AdditionalQuestionContainer = styled.div<AdditionalQuestionProps>`
  background-color: ${colors.core02};
  padding: ${spacing(4)} ${spacing(2)} ${spacing(2)};

  ${(props) => (props.standardStyleRequired ? standardQuestionStyle : '')}

  ${mediaQuery.tabletLandscape`
    padding: ${spacing(6)} ${spacing(8)} ${spacing(2)};
  `}
`;

export const StyledQuestionField = styled(QuestionField)`
  ${Grid} > ${GridItem} {
    ${gridItemPropsOverride({ desktop: 10, tabletLandscape: 10, tabletPortrait: 8 })};
  }
  
  &&& legend {
    & {
      font-weight: ${heavyFontWeight};
    }
  }
  
  margin-top: ${spacing(4)};
  margin-bottom: ${spacing(2)};

  ${mediaQuery.tabletLandscape`
    margin-bottom: ${spacing(4)};
  `}
`;

import React from 'react';
import {
  ChartText,
  ChartTextContainer,
  DonutChartWrapper,
  DonutRing,
  DonutSegment,
  FixedSizeContainer,
  FullSizedSvg,
} from './styles';

type DonutChartProps = {
  coveredClaimsPercentage: number;
  chartText: string;
};

const DonutChart: React.FC<DonutChartProps> = ({
  coveredClaimsPercentage,
  chartText,
}) => {
  // Circumference needs to be 100 for the cover percentage to work nicely
  const radius = 100 / (Math.PI * 2);

  return (
    <DonutChartWrapper>
      <FixedSizeContainer>
        {/* 34 is the biggest width/height value before the viewbox starts clipping the chart */}
        <FullSizedSvg viewBox="0 0 34 34">
          <DonutRing r={radius} cx="50%" cy="50%" />
          <DonutSegment
            r={radius}
            cx="50%"
            cy="50%"
            radius={radius}
            percentageCovered={coveredClaimsPercentage}
          />
        </FullSizedSvg>
        <ChartTextContainer>
          <ChartText html={chartText} />
        </ChartTextContainer>
      </FixedSizeContainer>
    </DonutChartWrapper>
  );
};

export default DonutChart;

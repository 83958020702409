import RichText from '@rsa-digital/evo-shared-components/components/RichText';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import styled, { css, Keyframes, keyframes, StyledProps } from 'styled-components';

type AnimationProps = StyledProps<{
  percentageCovered: number;
  radius: number;
}>;

const sharedDonutStyling = css`
  fill: transparent;
  stroke-width: 1.5;
`;

const segmentAnimation = (filled: number): Keyframes => keyframes`
  0% {
    stroke-dasharray: 0 100;
  }
  100% {
    stroke-dasharray: ${filled} ${100 - filled};
  }
`;

export const FullSizedSvg = styled.svg`
  height: 100%;
`;

export const DonutRing = styled.circle`
  ${sharedDonutStyling}
  stroke: ${colors.neutral04};
`;

export const DonutSegment = styled.circle<AnimationProps>`
  ${sharedDonutStyling};
  stroke: ${colors.selectorHighlight};
  stroke-dashoffset: 25;
  ${({ percentageCovered }) => css`
    stroke-dasharray: ${percentageCovered} ${100 - percentageCovered};
    animation: 1s ${segmentAnimation(percentageCovered)} forwards;
  `}
`;

export const ChartText = styled(RichText)`
  ${fonts.paragraph};

  strong {
    color: ${colors.core01};
  }
`;

export const FixedSizeContainer = styled.div`
  width: 200px;
  height: 200px;
  position: relative;
`;

export const ChartTextContainer = styled.div`
  width: 100%;
  padding: ${spacing(4)};
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

export const DonutChartWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin-top: ${spacing(3)};
  order: 2;

  ${mediaQuery.tabletPortrait`
    order: 3;
    margin-top: 0;
    display: block;
  `}

  ${mediaQuery.tabletLandscape`
    padding-left: ${spacing(4)};
  `}
`;

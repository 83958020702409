import { Grid } from '@rsa-digital/evo-shared-components/components/Grid';
import { ButtonLink } from '@rsa-digital/evo-shared-components/components/Link';
import colors from '@rsa-digital/evo-shared-components/helpers/colors';
import fonts from '@rsa-digital/evo-shared-components/helpers/fonts';
import mediaQuery from '@rsa-digital/evo-shared-components/helpers/mediaQuery';
import spacing from '@rsa-digital/evo-shared-components/helpers/spacing';
import { lightFontWeight } from '@rsa-digital/evo-shared-components/theme/Tesco/fonts';
import styled from 'styled-components';
import RichTextWithModal from 'components/RichTextWithModal';

export const StyledSpan = styled.span`
  ${fonts.paragraph};
  margin: ${spacing(1)} 0;
  display: block;
`;

export const StyledButtonLink = styled(ButtonLink)`
  ${fonts.paragraph}
  & {
    color: ${colors.core01};
  }
  text-decoration: underline;
  margin-top: ${spacing(1)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(2)};
  `}
`;

export const ButtonLinkContainer = styled.div`
  display: flex;
  justify-content: center;

  ${mediaQuery.tabletPortrait`
    justify-content: flex-start;
  `}
`;

export const TitleBannerConainer = styled.div`
  background-color: ${colors.core01};
  padding: ${spacing(2)};

  ${mediaQuery.tabletLandscape`
    padding-left: ${spacing(6)};
  `}
`;

export const TitleText = styled.p`
  ${fonts.headingSmall};
  & {
    color: ${colors.core02};
  }

  margin: 0;
`;

export const GridWithMarginTop = styled(Grid)`
  margin-top: ${spacing(3)};

  ${mediaQuery.tabletPortrait`
    margin-top: ${spacing(4)};
  `}
`;

export const ProductDetailsContainer = styled.div`
  background-color: ${colors.core02};
  page-break-inside: avoid;

  padding: ${spacing(3)} ${spacing(2)};

  ${mediaQuery.tabletPortrait`
    padding: ${spacing(6)};
  `}
`;

export const ParagraphLightWithMarginTop = styled.p`
  ${fonts.paragraphSmall};
  & {
    font-weight: ${lightFontWeight};
    max-width: 560px;
  }

  margin: ${spacing(2)} 0 0;
`;
export const StyledFlexibleWidthDivider = styled.div`
  padding-top: ${spacing(3)};
  padding-bottom: ${spacing(1)};
`;
export const MoneyFactsBanner = styled.div`
  padding: ${spacing(3)} ${spacing(3)};
  background: ${colors.core02};
  text-align: center;
  width: 100%;
  ${mediaQuery.tabletPortrait`
    padding: ${spacing(3)} ${spacing(8)};
    text-align: left;
  `}
`;
export const StyledRichText = styled(RichTextWithModal)`
  margin-bottom: ${spacing(2)};
`;

export const SectionHeading = styled.h2`
  ${fonts.paragraph};
  margin: ${spacing(3)} 0 ${spacing(1)};
  width: 100%;
  && {
    font-weight: 700;
  }
  ${mediaQuery.tabletPortrait`
     margin: ${spacing(1)} 0;
  `}
`;

export const ImageWrapper = styled.div`
  min-width: ${spacing(15)};
  width: ${spacing(15)};
  height: auto;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-grow: 1;

  ${mediaQuery.tabletPortrait`
    margin-left: ${spacing(4)};
  `}
`;

export const StyledGrid = styled(Grid)`
  align-items: center;
  ${mediaQuery.tabletPortrait`
  justify-content: flex-start;
  `}
`;
export const GridWithFullWidth = styled(Grid)`
  margin: 0 -${spacing(2)} ${spacing(2)};
  width: calc(100% + ${spacing(4)});
  ${mediaQuery.tabletPortrait`
    margin: 0 -${spacing(6)} ${spacing(4)};
    width: calc(100% + ${spacing(12)});
  `}
`;

import componentProps, {
  ComponentProps,
} from '@rsa-digital/evo-shared-components/helpers/componentProps';
import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import {
  ContentWrapper,
  Divider,
  ExampleWrapper,
  StyledPanel,
  StyledRichText,
  SubheadingRichText,
} from './styles';

type ExampleBoxProps = {
  id: string;
  subheading: string;
  averageClaimText: string;
  averageClaimPayment: string;
  largeClaimText: string;
  largeClaimPayment: string;
  pageTitle: PageTitle;
  addBottomMargin?: boolean;
} & ComponentProps;

const ExampleBox: React.FC<ExampleBoxProps> = ({
  id,
  subheading,
  averageClaimText,
  averageClaimPayment,
  largeClaimText,
  largeClaimPayment,
  pageTitle,
  addBottomMargin,
  ...rest
}) => {
  return (
    <StyledPanel {...componentProps(rest)} addBottomMargin={addBottomMargin} id={id}>
      <SubheadingRichText html={subheading} />
      <ContentWrapper>
        <ExampleWrapper>
          <StyledRichText html={averageClaimText} pageTitle={pageTitle} />
          <div>
            <Divider />
            <StyledRichText html={averageClaimPayment} pageTitle={pageTitle} />
          </div>
        </ExampleWrapper>
        <ExampleWrapper>
          <StyledRichText html={largeClaimText} pageTitle={pageTitle} />
          <div>
            <Divider />
            <StyledRichText html={largeClaimPayment} pageTitle={pageTitle} />
          </div>
        </ExampleWrapper>
      </ContentWrapper>
    </StyledPanel>
  );
};

export default ExampleBox;

import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { graphql, useStaticQuery } from 'gatsby';
import { ExtraCoverFeeLimit, PremierCoverFeeLimit } from 'state/formData/quoteOptions';
import { warningWithDetail } from './errorReporting';
import { Product } from './productHelpers';
import { petType_CAT, petType_DOG } from './referenceDataConstants';

type CoverPercentages = {
  standard: number;
  extra_4000: number;
  extra_7500: number;
  premier_2000: number;
  premier_4000: number;
  premier_7500: number;
  premier_10000: number;
};

type CsCoverPercentages = {
  dog_only: CoverPercentages;
  cat_only: CoverPercentages;
  multipet: CoverPercentages;
};

type CsPercentageOfClaimsCovered = {
  csPetQuoteSummaryPercentageOfClaimsCoveredBanner: {
    cover_percentages: CsCoverPercentages;
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryPercentageOfClaimsCoveredBanner {
      cover_percentages {
        dog_only {
          standard
          extra_4000
          extra_7500
          premier_2000
          premier_4000
          premier_7500
          premier_10000
        }
        cat_only {
          standard
          extra_4000
          extra_7500
          premier_2000
          premier_4000
          premier_7500
          premier_10000
        }
        multipet {
          standard
          extra_4000
          extra_7500
          premier_2000
          premier_4000
          premier_7500
          premier_10000
        }
      }
    }
  }
`;

const getApplicableCoverPercentagesForPetsOnQuote = (
  petInfos: QuoteResponsePetInfo[],
  coverPercentages: CsCoverPercentages
): CoverPercentages => {
  if (petInfos.find((petInfo) => petInfo.petType === petType_CAT)) {
    return petInfos.find((petInfo) => petInfo.petType === petType_DOG)
      ? coverPercentages.multipet
      : coverPercentages.cat_only;
  }

  return coverPercentages.dog_only;
};

const usePercentageOfClaimsCovered = (
  product: Product,
  feeLimit: number,
  petInfos: QuoteResponsePetInfo[]
): number => {
  const { cover_percentages } = useStaticQuery<CsPercentageOfClaimsCovered>(
    query
  ).csPetQuoteSummaryPercentageOfClaimsCoveredBanner;
  const coverPercentages = getApplicableCoverPercentagesForPetsOnQuote(
    petInfos,
    cover_percentages
  );

  /* Covering each of these cases is not valuable */
  /* istanbul ignore if */
  if (product === Product.Standard) {
    return coverPercentages.standard;
  }

  /* Covering each of these cases is not valuable */
  /* istanbul ignore if */
  if (product === Product.Extra) {
    switch (feeLimit) {
      case ExtraCoverFeeLimit.Limit_4000:
        return coverPercentages.extra_4000;
      case ExtraCoverFeeLimit.Limit_7500:
        return coverPercentages.extra_7500;
      default:
        warningWithDetail(
          'Could not find percentage of claims covered for that vet fee limit',
          'this could be caused by updating percentages in CS but not in the enum'
        );
        return 0;
    }
  }

  /* Covering each of these cases is not valuable */
  /* istanbul ignore if */
  if (product === Product.Premier) {
    switch (feeLimit) {
      case PremierCoverFeeLimit.Limit_2000:
        return coverPercentages.premier_2000;
      case PremierCoverFeeLimit.Limit_4000:
        return coverPercentages.premier_4000;
      case PremierCoverFeeLimit.Limit_7500:
        return coverPercentages.premier_7500;
      case PremierCoverFeeLimit.Limit_10000:
        return coverPercentages.premier_10000;
      default:
        warningWithDetail(
          'Could not find percentage of claims covered for that vet fee limit',
          'this could be caused by updating percentages in CS but not in the enum'
        );
        return 0;
    }
  }

  /* istanbul ignore next */
  warningWithDetail(
    'Could not find percentage of claims covered for that product type',
    'currently, this hook should only be used for extra / premier cover'
  );
  return 0;
};

export default usePercentageOfClaimsCovered;

import { generateKeyForElement } from '@rsa-digital/evo-shared-components/helpers/elementKeyHelper';
import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { trimEnd } from 'lodash';
import React from 'react';
import { PageTitle } from 'helpers/eventTracking';
import { useExcessPlaceholderData } from 'helpers/useExcessPlaceholderData';
import CoinsuranceExcessStatement from './CoinsuranceExcessStatement';
import {
  BoldParagraphLargeWithMarginBottom,
  StyledListItem,
  StyledUnorderedList,
} from './styles';
import { LargeRichTextWithModal } from '../styles';

type CoinsuranceExcessStatementsProps = {
  pageTitle: PageTitle;
  petInfos: QuoteResponsePetInfo[];
  textAfterStatement?: string;
};

const CoinsuranceExcessStatements: React.FC<CoinsuranceExcessStatementsProps> = ({
  pageTitle,
  petInfos,
  textAfterStatement,
}) => {
  const multipetQuote = petInfos.length > 1;

  const placeholderData = useExcessPlaceholderData(petInfos);

  return (
    <>
      {multipetQuote ? (
        <StyledUnorderedList>
          {petInfos.map((pet, index) => (
            <StyledListItem key={generateKeyForElement(pet)}>
              <BoldParagraphLargeWithMarginBottom>
                {trimEnd(pet.petName)}:
              </BoldParagraphLargeWithMarginBottom>
              <CoinsuranceExcessStatement
                pageTitle={pageTitle}
                petInfo={pet}
                placeholderData={placeholderData[index]}
              />
            </StyledListItem>
          ))}
        </StyledUnorderedList>
      ) : (
        <CoinsuranceExcessStatement
          pageTitle={pageTitle}
          petInfo={petInfos[0]}
          placeholderData={placeholderData[0]}
        />
      )}
      {textAfterStatement && (
        <LargeRichTextWithModal pageTitle={pageTitle} html={textAfterStatement} />
      )}
    </>
  );
};

export default CoinsuranceExcessStatements;

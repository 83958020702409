import { Grid, GridItem } from '@rsa-digital/evo-shared-components/components/Grid';
import { QuoteUpdaters } from 'apiHelpers/quote/useUpdateQuoteCover';
import { graphql, useStaticQuery } from 'gatsby';
import { trimEnd } from 'lodash';
import React, { SetStateAction } from 'react';
import { VoluntaryExcessPercentage } from 'helpers/businessConstants';
import useDefaultErrorHandling from 'helpers/errorHandling';
import { PageTitle, trackPercentageExcessSelection } from 'helpers/eventTracking';
import { petNameReplacer } from 'helpers/placeholders/dynamicPetNameHelpers';
import { replacePlaceholdersQuestion } from 'helpers/placeholders/replaceCsPlaceholders';
import { useCurrentQuote } from 'helpers/useCurrentQuote';
import useQuestionProcessor from 'helpers/useQuestionProcessor';
import { CsQuestion } from 'types/contentStack';
import {
  ExplanatoryText,
  StyledSegmentedSelector,
  SubheadingSmall,
  Wrapper,
} from './styles';

type AdditionalExcessSectionProps = {
  quoteUpdaters: QuoteUpdaters;
  petIndex: number;
  excessPercentageAmounts: VoluntaryExcessPercentage[] | undefined;
  setExcessPercentageAmounts: React.Dispatch<
    SetStateAction<VoluntaryExcessPercentage[] | undefined>
  >;
};

type CsAdditionalExcessSection = {
  csPetQuoteSummaryChooseExcess: {
    additional_vet_excess: {
      choose_additional_excess_question: CsQuestion;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcess {
      additional_vet_excess {
        choose_additional_excess_question {
          main_details {
            question_text
            explanatory_text
            tooltip {
              tooltip_text
              tooltip_open
              tooltip_close
            }
            alert_text
          }
        }
      }
    }
  }
`;

const AdditionalExcess: React.FC<AdditionalExcessSectionProps> = ({
  quoteUpdaters,
  petIndex,
  excessPercentageAmounts,
  setExcessPercentageAmounts,
}) => {
  const csContent = useStaticQuery<CsAdditionalExcessSection>(query)
    .csPetQuoteSummaryChooseExcess.additional_vet_excess;
  const { processQuestion } = useQuestionProcessor(PageTitle.QuoteSummary);
  const excessQuestion = processQuestion(csContent.choose_additional_excess_question);

  const quote = useCurrentQuote();

  const defaultErrorHandling = useDefaultErrorHandling();

  const substituteName = replacePlaceholdersQuestion((csString: string) =>
    petNameReplacer(trimEnd(quote.petInfos?.[petIndex].petName) || '', csString)
  );

  const updateExcessAmountValues = (
    newValue: VoluntaryExcessPercentage
  ): VoluntaryExcessPercentage[] => {
    const excessPercentageAmountsUpdated = excessPercentageAmounts
      ? [...excessPercentageAmounts]
      : [];
    excessPercentageAmountsUpdated[petIndex] = newValue;
    return excessPercentageAmountsUpdated;
  };

  return (
    <Wrapper>
      <div>
        <SubheadingSmall>{substituteName(excessQuestion).questionText}</SubheadingSmall>
        <ExplanatoryText>
          {substituteName(excessQuestion).explanatoryText}
        </ExplanatoryText>
      </div>
      <Grid alignLeft>
        <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8}>
          <Grid alignLeft id={`petAdditionalExcessQuestion_[${petIndex}]`}>
            <GridItem desktop={10} tabletLandscape={10} tabletPortrait={8}>
              <StyledSegmentedSelector
                disabled={!!quoteUpdaters?.isLoading}
                id={`petAdditionalExcess[${petIndex}]`}
                shownOptions={3}
                value={excessPercentageAmounts?.[petIndex]}
                options={[
                  { name: '0%', value: VoluntaryExcessPercentage.EXCESS_0_PERCENT },
                  { name: '10%', value: VoluntaryExcessPercentage.EXCESS_10_PERCENT },
                  {
                    name: '20%',
                    value: VoluntaryExcessPercentage.EXCESS_20_PERCENT,
                  },
                ]}
                onChange={async (e) => {
                  if (!quoteUpdaters) {
                    console.error('No quote loaded - could not update quote');
                    return;
                  }
                  const excessPercentageAmountsUpdated = updateExcessAmountValues(
                    e.target.value as VoluntaryExcessPercentage
                  );
                  setExcessPercentageAmounts(excessPercentageAmountsUpdated);

                  trackPercentageExcessSelection(
                    petIndex,
                    e.target.value as VoluntaryExcessPercentage
                  );
                  try {
                    await quoteUpdaters.updatePetExcessPercentage(
                      petIndex,
                      e.target.value as VoluntaryExcessPercentage
                    );
                  } catch (err) {
                    const error: Error = err as Error;
                    defaultErrorHandling(error);
                  }
                }}
              />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Wrapper>
  );
};

export default AdditionalExcess;

import { QuoteResponsePetInfo } from 'apiHelpers/quote/quoteResponse';
import { graphql, useStaticQuery } from 'gatsby';
import { ExcessPlaceholderData } from './placeholders/excessPlaceholders';
import { useCurrentQuote } from './useCurrentQuote';
import useCurrentVetFeeLimit from './useCurrentVetFeeLimit';

type CsAdditionalExcessSection = {
  csPetQuoteSummaryChooseExcess: {
    additional_vet_excess: {
      average_claim_value: number;
    };
  };
};

const query = graphql`
  query {
    csPetQuoteSummaryChooseExcess {
      additional_vet_excess {
        average_claim_value
      }
    }
  }
`;

export const useExcessPlaceholderData = (
  petInfos: QuoteResponsePetInfo[]
): ExcessPlaceholderData[] => {
  const csContent = useStaticQuery<CsAdditionalExcessSection>(query)
    .csPetQuoteSummaryChooseExcess.additional_vet_excess;

  const quote = useCurrentQuote();
  const currentVetFee = useCurrentVetFeeLimit(quote.quoteOptions);

  return petInfos?.map((petInfo) => ({
    fixedExcess: petInfo ? parseInt(petInfo.voluntaryExcessAmount, 10) : 0,
    percentageExcess: petInfo ? parseInt(petInfo.voluntaryExcessPercentage, 10) : 0,
    averageClaimValue: csContent.average_claim_value,
    vetFeeLimit: currentVetFee,
  }));
};
